import { Action } from 'utils/action';


export const autocompleteSearch = new Action({
  resourceType: 'searchAutocompleteResults',
  requestKey: 'searchAutocomplete',
  method: 'GET',
  effect: 'read',
  url: '/api/v1/global_search/autocomplete',
  alwaysRefresh: true,
  list: "autocompleteResults",
  disableSingleItemBehavior: true

});
