import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { intlKeyFromValue } from 'translations';
import CollapsibleSection from 'controls/collapsible_section';
import { Heading } from 'controls/heading';
import { ResultsItem } from './results_item';

import './results_category.sass';

const PER_CATEGORY_RESULT_LIMIT = 3;

/**
 * This component shows search results for a single object type (e.g. Skill, Organization, etc.) If
 * there are more than PER_CATEGORY_RESULT_LIMIT results, the extras are initially hidden, and will
 * be displayed upon clicking a "View all" link.
 *
 * @property {boolean} [alwaysExpanded=false] - whether search results beyond the
 *   PER_CATEGORY_RESULT_LIMIT are always visible, or only upon clicking "View All"
 * @property {String} category - the heading to display for these results
 * @property {Array<object>} results - search results
 *   @property {String} type - object type of result; one of {Skill, Organization, BadgeTemplate};
 *     other attributes of the item depend on this one
 * @property {String} searchTerm - search typed by user
 * @property {Function} componentTracking - Track search click
 */
const ResultsCategory = memo(({
  alwaysExpanded = false,
  category,
  results,
  searchTerm,
  componentTracking
}) => {
  const intl = useIntl();

  const [showAll, setShowAll] = useState(false);
  const [showAllExpanded, setShowAllExpanded] = useState(false);

  /**
   * Returns true if there are some results that can be expanded and collapsed by the user.
   * @returns {boolean}
   */
  const moreResults = () => {
    return !alwaysExpanded && results.length > PER_CATEGORY_RESULT_LIMIT;
  };

  /**
   * Renders a single search result item.
   * @param {Object} item - the result to render
   * @param {boolean} [focusItem=false] - whether to focus the item (set true if the item is the
   *   first in a set of newly-revealed items)
   * @returns {*}
   */
  const renderCategoryItem = (item, focusItem = false) => {
    return (
      <div className="c-global-search-results-category__item" role="listitem" key={item.id}>
        <ResultsItem
          {...item}
          focus={focusItem}
          searchTerm={searchTerm}
          componentTracking={componentTracking}
        />
      </div>
    );
  };

  /**
   * Returns the index at which results should be partitioned into always-visible and visible only
   * upon clicking on "View all".
   *
   * @returns {number}
   */
  const showMoreCutoff = () => {
    if (alwaysExpanded) {
      return results.length;
    } else {
      return PER_CATEGORY_RESULT_LIMIT;
    }
  };

  const aboveTheFold = results.slice(0, showMoreCutoff());
  const foldedItems = useMemo(() => {
    return results.slice(showMoreCutoff()).map((item, idx) => {
      const focus = !!(idx === 0 && showAllExpanded);
      return renderCategoryItem(item, focus);
    });
  }, [results, showAllExpanded]);

  /**
   * Renders a control to toggle visibility of results beyond the default-displayed threshold.
   * @returns {*}
   */
  const renderToggleMore = () => {
    return (
      <a
        href="#"
        className="c-global-search-results-category__view-all"
        onClick={toggleShowAll}
        aria-expanded={showAll}
      >
        {showAll ? (
          intl.formatMessage(
            {
              id: 'search.show_less',
              defaultMessage: 'Show less'
            }
          )
        ) : (
          intl.formatMessage(
            {
              id: 'search.view_all',
              defaultMessage: 'View all {length}'
            },
            { length: results.length }
          )
        )}
      </a>
    );
  };

  /**
   * Handler for control to toggle visibility of results beyond default-displayed threshold.
   * @param {Event} e - React Event that triggered the callback
   */
  const toggleShowAll = (e) => {
    e.preventDefault();
    setShowAll((prevShowAll) => !prevShowAll);
    setShowAllExpanded(false);
  };

  return (
    <div className="c-global-search-results-category">
      <Heading className="c-global-search-results-category__heading">
        {intl.formatMessage(
          {
            id: intlKeyFromValue(category, "search"),
            defaultMessage: category
          })}
      </Heading>
      <div role="list">
        <div role="presentation" className="c-global-search-results-category__group">
          {aboveTheFold.map((item) => renderCategoryItem(item))}
        </div>
        {foldedItems.length > 0 && (
          <CollapsibleSection
            collapsed={!showAll}
            tagName="div"
            className="c-global-search-results-category__group"
            extraProps={{ role: "presentation" }}
            onExpanded={() => setShowAllExpanded(true)}
          >
            {foldedItems}
          </CollapsibleSection>
        )}
      </div>
      {moreResults() && renderToggleMore()}
    </div>
  );
});

ResultsCategory.propTypes = {
  alwaysExpanded: PropTypes.bool,
  category: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(PropTypes.shape({ ...ResultsItem.propTypes })).isRequired,
  searchTerm: PropTypes.string,
  componentTracking: PropTypes.func.isRequired
};

ResultsCategory.displayName = 'ResultsCategory';
export default ResultsCategory;
