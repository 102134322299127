import { createSelector } from 'reselect';

export const makeGroupByTypeSelector = () => createSelector(
  (results) => results,
  (results) => {
    return results.reduce((result, item) => {
      const type = item.type;
      result[type] = result[type] || [];
      result[type].push(item);
      return result;
    }, {});
  }
);
