import React, { createContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { autocompleteSearch } from './action_creators';

export const AutocompleteContext = createContext();

const AUTOCOMPLETE_DEBOUNCE = 1000;
const SEARCH_DEBOUNCE = 300;


export const AutocompleteProvider = ({
  isEnabled,
  term,
  onSearch,
  onChange,
  children
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [autocompleteSearchResults, getAutocompleteSearchResults] = isEnabled
    ? autocompleteSearch.useAction() : [null, null];

  useEffect(() => {
    if (searchTerm.length && isEnabled) {
      getAutocompleteSearchResults({ q: searchTerm });
    }
  }, [searchTerm, getAutocompleteSearchResults]);

  /*
  Debounce search request. Some event call search twice,
  This prevents multiple calls with the same query
  */
  const debounceSearch = useCallback(debounce((value) => {
    onChange({ target: { value: value } });
    onSearch(value);
  }, SEARCH_DEBOUNCE, { maxWait: 500 }), []);


  /*
  as the user types, debounce the autocomplete api call
  */
  const debounceSetSearchTerm = useCallback(debounce((value) => {
    setSearchTerm(value?.trim()?.toLowerCase());
  }, AUTOCOMPLETE_DEBOUNCE, { maxWait: 1000, leading: true }), []);

  useEffect(() => {
    if (term?.trim()?.length) {
      debounceSetSearchTerm(term);
    }
  }, [term]);

  return (
    <AutocompleteContext.Provider value={{
      term,
      autocompleteResponse: autocompleteSearchResults,
      debounceSearch,
      isEnabled,
      onChange,
      value: term
    }}
    >
      {children}
    </AutocompleteContext.Provider>
  );
};


AutocompleteProvider.propTypes = {
  children: PropTypes.node.isRequired,
  term: PropTypes.string,
  isEnabled: PropTypes.bool,
  onSearch: PropTypes.func,
  onChange: PropTypes.func
};
