import React from 'react';
import { DefaultSiteHeader } from './default_site_header';
import { SiteHeaderContext } from './site_header_context';

/**
 * Render the site header - either default or custom - based on the current SiteHeaderContext value.
 * @param {Object} _props - ignored; React props for component
 * @returns {React.element}
 * @constructor
 */
export const SiteHeader = (_props) => (
  <SiteHeaderContext.Consumer>
    {value => value.customHeader || <DefaultSiteHeader/>}
  </SiteHeaderContext.Consumer>
);
