import { joinCompact } from 'utils/array';

/**
 * Constructs and returns the user's full name.
 *
 * @param {object} user - the user data
 * @returns {string} - the string containing the user's full name (first and last)
 */
export const userFullName = (user) =>
  joinCompact([user.first_name, user.middle_name, user.last_name], ' ');

/**
 * Constructs and returns the user's full location (city and state, perhaps eventually, country)
 *
 * @param {object} user - the user data
 * @returns {string} - the string containing the user's full location
 */
export const userFormattedLocation = (user) => (
  [user.city, user.state].filter(s => s).join(', ')
);
