import React from 'react';
import { useCurrentHeadingLevel } from 'controls/heading';

export const useAriaHeadingProps = () => {
  const headingLevel = useCurrentHeadingLevel();
  return { role: 'heading', 'aria-level': headingLevel };
};

export const withAriaHeadingProps = (OriginalComponent, { nestedIn = false }) => (
  (props) => {
    let ariaHeadingProps = useAriaHeadingProps();
    if (nestedIn) {
      ariaHeadingProps = { [nestedIn]: ariaHeadingProps };
    }
    return (
      <OriginalComponent {...props} {...ariaHeadingProps} />
    );
  }
);
