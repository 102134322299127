import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { makeClassName, uniqueId } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { withSearchContextConsumer, SearchContextPropTypes } from './search_context';
import { HandleBlurFromExternalEvent } from 'controls/handle_blur_from_external_event';
import { AutocompleteProvider, SearchBarAutocomplete } from './autocomplete';
import { useWidthBreakpoints } from "utils/use_width_breakpoints";


import './search_bar.sass';

const DEFAULT_SEARCH_DELAY = 300;
const THEME_TO_CSS_CLASS = {
  light: 'cr-search-bar--light',
  dark: 'cr-search-bar--dark',
  'light-short': 'cr-search-bar--light-short',
  'org-mgmt': 'cr-search-bar--org-mgmt',
  recruit: 'cr-search-bar--org-mgmt',
  'compact-borderless': 'cr-search-bar--compact-borderless',
  'new-theme': 'cr-search-bar--new-theme',
  'org-dashboard': 'cr-search-bar--org-dashboard'
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};


/**
 * Search bar, for use with the Search component (as SearchBar).
 *
 * @property {Boolean} [globalAutocompleteSearch=false] - Enable to prevent auto-search and props.search cb from triggering
 * at the end of a user typing debounced event, essentially requiring manual input (Enter, click)
 * handled by SearchBarAutocomplete.
 * @property {String} [theme=light] - Select one of the standard search bar styles. One of:
 *   light: Bordered search bar, with white background.
 *   dark: Dark background with white underline.
 *   light-short: Like "light," but shorter.
 *   org-mgmt: gray background, bordered search bar
 *   recruit: same at org-mgmt for now, just with different name
 *   compact-borderless: Even shorter than "light-short", and without a border
 * @property {String} [className=] - A custom CSS class.
 * @property {String} [placeholder=] - Placeholder text.
 * @property {String} [label=] - Field label.
 * @property {Object} [labelProps=] - props for Field label.
 * @property {Boolean} [showIcon=false] - Show the search icon.
 * @property {Boolean} [shrunk] - Shows only the search icon (like a collapsed mode).
 * @property {Function} [onShrink] - Callback function to shrink elements up in the tree hierarchy.
 * @property {String} [term] - if defined, will populate or overwrite the current term and trigger
 * @property {String} [searchDelay] - if defined, overrides the 300ms default
 * @property {Boolean} [isLoggedIn] - Show narrow bar at smaller breakpoints
 *   a new search for the provided term (i.e. allows SearchBar to be used in a semi-controlled
 *   fashion)
 * Additional properties come from SearchContext. See SearchContext and Search for more information.
 */
const SearchBar = ({
  focusNow,
  term,
  globalAutocompleteSearch,
  search,
  initialTerm,
  className,
  theme,
  showResults,
  isLoggedIn,
  onHasTermChange,
  toggleInputFocused,
  onFocus,
  onBlur,
  onTypingEnd,
  onTypingStart,
  handleEnter,
  handleEnterIncludesEvent,
  handleSearchClick,
  inputFocused,
  clearSearch,
  showIcon,
  searchIconPosition,
  placeholder,
  disabled,
  icon,
  label,
  labelProps,
  focusContainer,
  onShrink,
  shrink,
  clearSearchResults,
  searchDelay
}) => {
  const searchInput = React.createRef();
  const uniqueIdForInput = uniqueId();
  const breakpoint = useWidthBreakpoints();

  const [searchTerm, setSearchTerm] = useState(term || initialTerm || '');
  const [focused, setFocused] = useState(false);
  const [_shrink, setShrink] = useState(shrink);

  searchDelay = searchDelay || DEFAULT_SEARCH_DELAY;

  useEffect(() => {
    if (focusNow && focusNow.updateOnInit()) {
      searchInput.current.focus();
      setFocused(true);
    }
    if ((term || '').trim()) {
      onSearch.start(term);
    }
    if (globalAutocompleteSearch && term?.trim().length) {
      search(term);
    }
  }, [term, focusNow]);

  useEffect(() => {
    setShrink(shrink);
  }, [focused, breakpoint, shrink]);

  /**
   * Returns the class that the clear button should have.
   *
   * @returns {string}
   */
  const clearButtonClass = () => {
    return makeClassName([
      'cr-search-bar__clear',
      !searchTerm.trim() && 'cr-search-bar__clear--is-hidden',
      (_shrink || shrink) && 'cr-search-bar__clear--is-hidden',
      globalAutocompleteSearch && 'cr-search-bar__clear--autocomplete'
    ]);
  };

  /**
   * Returns the class that the search bar should have.
   *
   * @returns {string}
   */
  const searchBarClass = () => {
    return makeClassName([
      className,
      'cr-search-bar',
      THEME_TO_CSS_CLASS[theme || 'light'],
      showResults && 'cr-search-bar--showing-results',
      ...(!isLoggedIn ? ['cr-search-bar--unauthenticated'] : [])
    ]);
  };

  /**
   * Handler for input change.
   *
   * @param {Event} evt - React Event representing the event that triggered this callback
   */
  const onChange = (evt) => {
    setSearchTerm(evt.target.value);
    onHasTermChange(evt.target.value.trim().length > 0);
  };

  /**
   * The input has come into focus.
   *
   * @param {Event} evt
   */
  const internalOnFocus = (evt) => {
    onFocus && onFocus(evt);
    toggleInputFocused();
    setFocused(true);
    setShrink(false);
    onShrink && onShrink(_shrink);
  };

  /**
   * The Search Icon has been clicked.
   *
   * @param {Event} evt
   */
  const onClickSearchIcon = (evt) => {
    onFocus(evt);
    // eslint-disable-next-line no-unused-expressions
    searchInput?.current?.focus();
  };

  /**
   * The input has been blurred.
   */
  const internalOnBlur = () => {
    toggleInputFocused && toggleInputFocused();
    setFocused(false);
    setShrink((breakpoint === 'xs' || breakpoint === 'sm') && !searchTerm);
    onShrink && onShrink(_shrink);
  };

  /**
   * The control has blurred due to outside input.
   *
   * @param {Event} evt
   */
  const onExternalBlur = (evt) => {
    setFocused(false);
    if ((breakpoint === 'xs' || breakpoint === 'sm') && !searchTerm) {
      setShrink(true);
      onShrink && onShrink(_shrink);
    }
    onBlur && onBlur(evt);
  };

  /**
   * Clear the search term.
   */
  const clear = () => {
    if (globalAutocompleteSearch) {
      // eslint-disable-next-line no-unused-expressions
      searchInput?.current?.focus();
    }
    // Triggers onClear by way of componentDidUpdate.
    setSearchTerm('');
    onHasTermChange(false);
  };

  /**
   * The field has cleared.
   */
  const onClear = () => {
    onTypingEnd();
    search('');
    onSearch.cancel();
    if (clearSearchResults) {
      clearSearchResults(searchTerm);
    }
  };

  const onEnter = (evt) => {
    if (evt.key === 'Enter' && handleEnter) {
      if (handleEnterIncludesEvent) {
        handleEnter(searchTerm, evt);
      } else {
        handleEnter(searchTerm);
      }
    }
  };

  const onSearchClick = () => {
    handleSearchClick(searchTerm);
  };

  const debouncedStartTyping = useCallback(
    debounce(() => {
      onTypingStart();
    }, searchDelay, { leading: true, trailing: false })
    , []);

  const debouncedEndTyping = useCallback(
    debounce((value) => {
      onTypingEnd();
      !globalAutocompleteSearch && search(value);
    }, searchDelay), []);

  /**
   * Search functions.
   *
   * @returns {{start: function, cancel: function}}
   */
  const onSearch = (() => {
    // When start receiving input, update state to reflect that
    const start = debouncedStartTyping;

    // When we stop receiving input (for at least searchDelay ms), start a search and update state
    // to reflect that we've stopped receiving input
    const end = debouncedEndTyping;

    return {
      /**
       * Handler for change of search term.  Starts new search request for given value, assuming no
       * new input for searchDelay ms.
       *
       * @param {String} value - search value
       */
      start: value => {
        // Call both debounced functions, so that we get both leading and trailing behaviors
        start(value);
        end(value);
      },

      /**
       * Cancel function
       */
      cancel: () => {
        start.cancel();
        end.cancel();
      }
    };
  })();

  const prevTerm = usePrevious(searchTerm);
  const prevPropsTerm = usePrevious(term);
  const prevPropsFocusNow = usePrevious(focusNow);
  const prevPropsShowResults = usePrevious(showResults);
  const prevPropsClearSearch = usePrevious(clearSearch);

  useEffect(() => {
    const curTerm = searchTerm.trim();
    const prevTermTrimmed = prevTerm ? prevTerm.trim() : '';

    const curPropsTerm = (term || '').trim();
    const prevPropsTermTrimmed = (prevPropsTerm || '').trim();

    if (curPropsTerm !== prevPropsTermTrimmed && curTerm !== curPropsTerm) {
      setSearchTerm(curPropsTerm);
    }

    if (
      prevPropsFocusNow &&
      !inputFocused &&
      focusNow &&
      focusNow !== prevPropsFocusNow
    ) {
      searchInput.current.focus();
    }

    if (prevPropsShowResults && !showResults) {
      onSearch.cancel();
    }

    if (prevTermTrimmed && !curTerm) {
      onClear();
    } else if (
      prevPropsClearSearch &&
      clearSearch &&
      clearSearch.changed(prevPropsClearSearch)
    ) {
      clear();
    } else if (
      curTerm &&
      (curTerm.toLowerCase() !== prevTermTrimmed.toLowerCase()) &&
      !globalAutocompleteSearch
    ) {
      onSearch.start(searchTerm);
    }
  }, [
    searchTerm,
    term,
    focusNow,
    clearSearch
  ]);

  return (
    <div className={searchBarClass()}>
      <div className="cr-search-bar__input-group">
        {
          (showIcon && searchIconPosition === 'start') && (
            <FontAwesomeIcon
              onClick={onClickSearchIcon}
              icon={faSearch}
              className={makeClassName("cr-search-bar__search-icon--left",
                globalAutocompleteSearch && 'cr-search-bar__search-icon--left--autocomplete'
              )}
            />
          )
        }
        <AutocompleteProvider
          isEnabled={globalAutocompleteSearch}
          term={searchTerm}
          onSearch={(term) => search(term)}
          onChange={onChange}
        >
          <SearchBarAutocomplete
            className={makeClassName(
              'cr-search-bar__input',
              globalAutocompleteSearch && 'cr-search-bar__input--global-search',
              (showIcon && searchIconPosition === 'start') &&
              'cr-search-bar__input--with-left-icon',
              searchIconPosition === 'end' && 'cr-search-bar__input--no-clear',
              _shrink && 'cr-search-bar__input--icon-only'
            )}
            name="Search Bar"
            type="text"
            autoComplete="off"
            id={`search_input_${uniqueIdForInput}`}
            ref={searchInput}
            placeholder={placeholder}
            onFocus={internalOnFocus}
            onBlur={internalOnBlur}
            onKeyDown={onEnter}
            disabled={disabled}
          />
        </AutocompleteProvider>
        {icon
          ? <FontAwesomeIcon icon={icon} className="cr-search-bar__search-icon--right" />
          : null
        }
        {
          searchIconPosition === 'end'
            ? (
              <button onClick={onSearchClick} className="cr-search-bar__search-icon--right">
                <FontAwesomeIcon icon={faSearch} />
              </button>
            )
            : (
              <button
                className={clearButtonClass()}
                onClick={clear}
                aria-label="Clear search"
                type="button"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )
        }
      </div>
      {
        label && (
          <label
            {...(labelProps || {})}
            className="cr-search-bar__prompt"
            htmlFor={`search_input_${uniqueIdForInput}`}
          >
            {label}
          </label>
        )
      }
      <HandleBlurFromExternalEvent
        container={focusContainer}
        onBlur={onExternalBlur}
      />
    </div>
  );
};

// Pass context to Bar as props
const WithContext = withSearchContextConsumer(SearchBar);

WithContext.propTypes = {
  theme: PropTypes.oneOf([
    'compact-borderless', 'dark', 'light', 'light-short', 'org-mgmt', 'recruit', 'new-theme', 'org-dashboard']
  ),
  className: PropTypes.string,
  initialTerm: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showIcon: PropTypes.bool,
  shrink: PropTypes.bool,
  onShrink: PropTypes.func,
  searchIconPosition: PropTypes.oneOf(['start', 'end']),
  term: PropTypes.string
};

SearchBar.propTypes = {
  isLoggedIn: PropTypes.bool,
  globalAutocompleteSearch: PropTypes.bool,
  ...WithContext.propTypes,
  ...SearchContextPropTypes
};

SearchBar.defaultProps = WithContext.defaultProps = {
  placeholder: '',
  label: '',
  labelProps: PropTypes.object,
  theme: 'light',
  searchIconPosition: 'start'
};

export { WithContext as SearchBar };
export const testing = { SearchBar };
