import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { NewHeadingLevel } from 'controls/heading';
import { LoadingSpinner } from 'controls/loading_spinner';
import { useIntl } from 'react-intl';
import ResultsCategory from './results_category';
import ResultsFilters from './results_filters';
import { makeGroupByTypeSelector } from './selectors';
import Category from './category';

import './results.sass';

const EMPTY_RESULTS = [];

/**
 * Shows results of a global search query, including filters for the different results categories.
 *
 * @property {Array<{ label: String }>} searchResults - search results
 *   label: the object type of the result; one of {Skill, Organization, BadgeTemplate}
 *   other attributes depend on type
 * @property {String} [className] - optional extra class to add to root element
 * @property {String} searchTerm - search typed by user
 * @property {Function} componentTracking - Track search click
 */
const Results = ({
  className,
  searchResults,
  searchTerm,
  componentTracking,
  customResponse
}) => {
  const intl = useIntl();

  const [filter, setFilter] = useState('');

  /*
  on mount: if custom response has params, set that filter as active.
  */
  useEffect(() => {
    for (const k in customResponse) {
      if (customResponse[k]?.hasParams) {
        setFilter(k);
        break;
      }
    }
  }, [customResponse]);

  /**
   * Memoizing function that reorganizes array input into object of arrays keyed by the "type"
   * attribute of input objects.
   */
  const groupedResultsSelector = makeGroupByTypeSelector();

  /**
   * Returns searchResults grouped by their object type.
   */
  const groupedResults = groupedResultsSelector(searchResults);

  /**
   * Handler for change of object type filter.
   *
   * @param {String} filter - which object type to filter results by
   */
  const handleFilterChange = (filter) => {
    setFilter(filter);
  };

  /**
   * Renders section for each object type for which there are search results.
   *
   * @returns {React}
   */
  const renderAll = () => {
    const elResult = [];
    Category.getAllCategories().forEach((category) => {
      if (category.objectType in groupedResults) {
        elResult.push(
          <ResultsCategory
            category={category.label}
            results={groupedResults[category.objectType]}
            key={category.label}
            searchTerm={searchTerm}
            componentTracking={componentTracking}
          />
        );
      }
    });
    return elResult;
  };

  /**
   * Renders section for object type that results are currently filtered by.
   *
   * @returns {*}
   */
  const renderFiltered = () => {
    let filteredResults = groupedResults[filter] || EMPTY_RESULTS;
    const category = Category.getCategoryByObjectType(filter);

    // if custom response object is passed, render custom response instead
    const customResponseObj = customResponse?.[filter];
    if (customResponseObj?.hasParams) {
      if (customResponseObj.loader?.pending) {
        return <LoadingSpinner position="center" />;
      }
      if (!customResponseObj?.results?.length) {
        return (
          <div>
            {intl.formatMessage({
              id: 'search.results.count.description.empty',
              defaultMessage: 'No Results'
            })}
          </div>
        );
      }
      filteredResults = customResponseObj.results;
    }

    return (
      <ResultsCategory
        category={category.label}
        results={filteredResults}
        searchTerm={searchTerm}
        componentTracking={componentTracking}
        alwaysExpanded
      />
    );
  };

  return (
    <div className={makeClassName(className)}>
      <div className="c-global-search-results__container row">
        <ResultsFilters
          results={groupedResults}
          selected={filter}
          handleFilterChange={handleFilterChange}
          className="c-global-search-results__filters-container"
        />
        <div
          className="c-global-search-results__category-container"
          role="main"
          aria-label={intl.formatMessage({
            id: 'search.results_landmark',
            defaultMessage: 'Search results'
          })}
        >
          {!filter && (
            <div
              className="c-global-search-results__results-count-container"
              key="search results count"
              aria-live="polite"
            >
              <span className="c-global-search-results__results-count-text">
                {searchResults.length}
              </span>{' '}
              {intl.formatMessage({
                id: 'search.results.count.description',
                defaultMessage: 'Results'
              })}
            </div>
          )}
          <NewHeadingLevel>
            {filter ? renderFiltered() : renderAll()}
          </NewHeadingLevel>
        </div>
      </div>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  searchResults: PropTypes.array,
  searchTerm: PropTypes.string,
  componentTracking: PropTypes.func,
  customResponse: PropTypes.object
};

export default Results;
