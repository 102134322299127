import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'controls/modal';
import { onResizeManager } from 'utils/window_event_manager';

import './anchored_modal.sass';

/**
 * Renders some content using a modal backdrop and focus trap such that the content is anchored to
 * the corner of the provided element.  If the content would overflow from the viewport, it is
 * adjusted as minimally as possible so that the entire element is visible.
 *
 * Repositions modal content if the window is resized, but does not attempt to monitor content size
 * changes.
 *
 * @param {object} props
 * @param {string} [props.alignment="top-left"] - which corner of the anchor element to align the
 *   modal content with; one of "top-left", "top-right", "bottom-right", "bottom-left"
 * @param {function():DOMRect} props.anchorBounds - a function that can be called to retrieve the
 *   bounds of the anchor
 * @param {function({openFocusRecipientRef})} props.children - a render prop to produce the content
 *   that wil be shown in the modal; receives a ref to assign to the element that should receive
 *   focus when the modal opens (see {@link Modal})
 * @param {function} props.onClose - function to be called when the modal should close (e.g. due to
 *   Escape key being pressed); calling this should cause props.show to become false
 * @param {boolean} props.show - whether the modal should be open
 * @returns {JSX.Element|null}
 * @constructor
 */
export const AnchoredModal = (props) => {
  const contentRef = useRef();
  const [contentPosition, setContentPosition] = useState({});

  // listener to reposition modal if the position of the anchor changes, e.g. because of a screen
  // resize
  const onResize = useCallback(() => {
    const newPosition = positionFromAlignment(
      props.anchorBounds(), contentRef.current, props.alignment
    );
    setContentPosition(newPosition);
  }, [props.anchorBounds, props.alignment]);

  // when the modal is visible, listens for window size changes and adjusts the position of the
  // modal accordingly
  useEffect(() => {
    if (props.show) {
      onResizeManager.add(onResize);
      return () => {
        onResizeManager.remove(onResize);
      };
    }
  }, [props.show, onResize]);

  // when the modal first opens, measure it and set its position accordingly
  useLayoutEffect(() => {
    if (props.show) {
      onResize();
    }
  }, [props.show, onResize]);

  if (props.show) {
    return (
      <Modal onClose={props.onClose} backdropTheme="light">
        {
          ({ openFocusRecipientRef }) => (
            <div
              className="cr-anchored-modal__container"
              style={contentPosition}
              ref={contentRef}
            >
              {
                props.children({ openFocusRecipientRef })
              }
            </div>
          )
        }
      </Modal>
    );
  } else {
    return null;
  }
};

AnchoredModal.propTypes = {
  alignment: PropTypes.oneOf(['top-left', 'top-right', 'bottom-right', 'bottom-left']),
  anchorBounds: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

AnchoredModal.defaultProps = {
  alignment: 'top-left',
  show: false
};

/**
 * Compute position style for the modal content, based on the bounds of the anchor, the size of the
 * content, and the designed alignment.
 *
 * @param {DOMRect} anchorBounds - object containing the boundaries of the anchor
 * @param {HTMLElement} content - the element containing the content of the modal, which will be
 *   measured to determine where it can be positioned
 * @param {string} alignment - the desired alignment
 * @returns {object}
 */
const positionFromAlignment = (anchorBounds, content, alignment) => {
  // in case we weren't able to get the actual bounds for some reason, fake them
  anchorBounds = anchorBounds || { top: 0, right: 0, bottom: 0, left: 0 };
  const viewportHeight = document.documentElement.clientHeight;
  const viewportWidth = document.documentElement.clientWidth;
  const contentBounds = content.getBoundingClientRect();
  const contentHeight = contentBounds.height;
  const contentWidth = contentBounds.width;

  const top = Math.max(0, Math.min(anchorBounds.top, viewportHeight - contentHeight));
  const left = Math.max(0, Math.min(anchorBounds.left, viewportWidth - contentWidth));
  const right = Math.max(0, viewportWidth - Math.max(anchorBounds.right, contentWidth));
  const bottom = Math.max(0, viewportHeight - Math.max(anchorBounds.bottom, contentHeight));

  switch (alignment) {
    case 'top-right':
      return { top, right };
    case 'bottom-right':
      return { bottom, right };
    case 'bottom-left':
      return { bottom, left };
    case 'top-left':
    default:
      return { top, left };
  }
};
