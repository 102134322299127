import React from 'react';
import PropTypes from 'prop-types';
import { SmartLink } from 'controls/smart_link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons/faLongArrowLeft';

import './context_header.sass';

/**
 * Component that presents a standard view/layout for a context-specific header, i.e. one that
 * includes a small representation of the object that is the "context" for the current view, and
 * a back link to return to that object.
 *
 * @property {string} backLink - a URL to return back to the context object's main page
 * @property {string} backLinkAriaLabel - an ariaLabel to add to the backLink
 * @property {*} children - a representation of the context object
 * @returns {React.element}
 */
export const ContextHeader = ({ backLink, backLinkAriaLabel, children }) => {
  return (
    <>
      <div className="cr-context-header__placeholder"/>
      <div className="cr-context-header container">
        <div className="cr-context-header__content row align-items-center">
          <div className="col-2 col-sm-1">
            <SmartLink
              action={backLink}
              className="cr-context-header__back-link"
              aria-label={backLinkAriaLabel}
            >
              <FontAwesomeIcon icon={faLongArrowLeft}/>
            </SmartLink>
          </div>
          <div className="col-10 col-sm-11">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

ContextHeader.propTypes = {
  backLink: PropTypes.string.isRequired,
  backLinkAriaLabel: PropTypes.string.isRequired,
  children: PropTypes.node
};
