import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { faAward } from '@fortawesome/pro-regular-svg-icons/faAward';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons/faLayerGroup';
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons/faDollarSign';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/pro-regular-svg-icons/faSliders";
import { makeClassName } from 'utils';
import { useWidthBreakpoints } from 'utils/use_width_breakpoints';
import CollapsibleSection from 'controls/collapsible_section';
import { Filter } from './filters';

import './badge_filters.sass';

const AVAILABLE_FILTERS = {
  type_category: {
    label: 'Type',
    icon: faAward,
    key: 'type_category',
    filters: [
      { label: 'Experience', key: 'experience' },
      { label: 'Learning', key: 'learning' },
      { label: 'Validation', key: 'validation' },
      { label: 'Certification', key: 'certification' }
    ]
  },
  level: {
    label: 'Level',
    icon: faLayerGroup,
    key: 'level',
    filters: [
      { label: 'Foundational', key: 'foundational' },
      { label: 'Intermediate', key: 'intermediate' },
      { label: 'Advanced', key: 'advanced' }
    ]
  },
  cost: {
    label: 'Cost',
    icon: faDollarSign,
    key: 'cost',
    filters: [
      { label: 'Free', key: 'free' },
      { label: 'Paid', key: 'paid' }
    ]
  }
};

export const BadgeFilters = ({ isActive }) => {
  const history = useHistory();
  const breakpoint = useWidthBreakpoints();
  const [isMounted, setIsMounted] = useState(false);

  const isSmallScreen = useMemo(() => {
    return breakpoint === 'sm' || breakpoint === 'xs';
  }, [breakpoint]);

  const [expandFilters, setExpandFilters] = useState(!isSmallScreen);
  const prevBreakpoint = useRef(breakpoint);

  useEffect(() => {
    // Defines if filters section shall be initially expanded as soon as breakpoint value is defined
    if (prevBreakpoint.current === undefined || prevBreakpoint.current === null) {
      if (breakpoint !== undefined && breakpoint !== null) {
        prevBreakpoint.current = breakpoint;
        setExpandFilters(!isSmallScreen);
      }
    }
  }, [breakpoint]);

  /*
  check url to see if previous filters have been applied on mount.
  */
  const getInitialFiltersAndActiveFilter = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const initialFilters = { type_category: [], cost: [], level: [] };
    const initialActiveFilter = { type_category: false, cost: false, level: false };

    for (const [key, value] of searchParams.entries()) {
      const normalizedKey = key.endsWith("[]") ? key.slice(0, -2) : key;
      // eslint-disable-next-line no-prototype-builtins
      if (initialFilters.hasOwnProperty(normalizedKey)) {
        if (!initialFilters[normalizedKey].includes(value)) {
          initialFilters[normalizedKey].push(value);
        }
        initialActiveFilter[normalizedKey] = true;
      }
    }

    return { initialFilters, initialActiveFilter };
  };

  const { initialFilters, initialActiveFilter } = getInitialFiltersAndActiveFilter();
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [activeFilter, setActiveFilter] = useState(initialActiveFilter);

  useEffect(() => {
    const { initialFilters, initialActiveFilter } = getInitialFiltersAndActiveFilter();
    setSelectedFilters(initialFilters);
    setActiveFilter(initialActiveFilter);
    setIsMounted(true);
    // Listener to history changes
    const unlisten = history.listen(() => {
      const { initialFilters, initialActiveFilter } = getInitialFiltersAndActiveFilter();
      setSelectedFilters(initialFilters);
      setActiveFilter(initialActiveFilter);
    });

    // Cleanup function to remove the listener when the component is unmounted
    return () => {
      unlisten();
    };
  }, []);

  const onFilterTypeClick = (filterType) => {
    setActiveFilter({ ...activeFilter, [filterType]: !activeFilter[filterType] });
  };

  /*
  Remove all filters from state and url parameters
  */
  const onFilterClear = useCallback(() => {
    setSelectedFilters({ type_category: [], cost: [], level: [] });
    history.push({
      pathname: history.location.pathname,
      hash: history.location.hash,
      search: ''
    });
  }, [history]);

  /*
  assure parameters are removed from url if filter is not selected
  */
  useEffect(() => {
    if (!isActive && isMounted) {
      onFilterClear();
    }
  }, [isActive, isMounted]);

  const onFilterRadioCheckboxClick = ({ filterType, filterItem }) => {
    const updatedFilters = { ...selectedFilters };

    if (filterType === 'cost') {
      // Handle radio button behavior for 'cost' filter type
      if (updatedFilters[filterType].includes(filterItem)) {
        // If the item is already selected, do nothing
        return;
      } else {
        // If the item is not selected, remove any other selected items and add this item
        updatedFilters[filterType] = [filterItem];
      }
    } else {
      // Handle checkbox behavior for other filter types
      const index = updatedFilters[filterType].indexOf(filterItem);

      if (index !== -1) {
        updatedFilters[filterType].splice(index, 1); // Remove the item if it's already selected
      } else {
        updatedFilters[filterType].push(filterItem); // Add the item if it's not selected
      }
    }

    setSelectedFilters(updatedFilters);

    // Updated query params generation to match the new URL structure
    const queryParams = Object.entries(updatedFilters)
      .flatMap(([key, values]) => values.map(value => `${key}[]=${value}`))
      .join('&');

    history.push({
      pathname: history.location.pathname,
      hash: history.location.hash,
      search: queryParams
    });
  };

  const isClearDisabled = useMemo(() => {
    return Object.values(selectedFilters).every((arr) => arr.length === 0);
  }, [selectedFilters]);

  const selectedFiltersLength = useMemo(() => {
    let length = 0;
    Object.values(selectedFilters).forEach((arr) => length += arr.length);
    return length;
  }, [selectedFilters]);


  return (
    <div className={makeClassName("c-global-search-results-filters__badge-filter-container",
      !isActive && "c-global-search-results-filters__badge-filter-container-collapsed")}
    >
      <div
        className="c-global-search-results-filters__badge-filter-header"
        onClick={() => setExpandFilters(!expandFilters)}
      >
        <span className="c-global-search-results-filters__badge-filter-container-title">
          <p className={
            makeClassName("c-global-search-results-filters__badge-filter-container-title-text",
              isSmallScreen &&
              "c-global-search-results-filters__badge-filter-container-title-text--mobile")}
          >
            {isSmallScreen ? 'Filters' : 'Filter by'} {isSmallScreen && selectedFiltersLength ? `(${selectedFiltersLength})` : null}
          </p>
          {isSmallScreen &&
            <FontAwesomeIcon
              className="c-global-search-results-filters__badge-filter-container-title-icon"
              icon={faSliders}
            />}
        </span>
        <div
          onClick={!isClearDisabled ? onFilterClear : undefined}
          className={
            `c-global-search-results-filters__badge-filter-container-title-clear${isClearDisabled ? ' disabled' : ''}`
          }
        >
          Clear
        </div>
      </div>
      <CollapsibleSection
        tagName="div"
        role="region"
        collapsed={!expandFilters}
      >
        {Object.keys(AVAILABLE_FILTERS).map(k => {
          const filter = AVAILABLE_FILTERS[k];
          return (
            <Filter
              key={filter.key}
              filterKey={filter.key}
              label={filter.label}
              filters={filter.filters}
              icon={filter.icon}
              activeFilter={activeFilter}
              onFilterTypeClick={onFilterTypeClick}
              onFilterRadioCheckboxClick={onFilterRadioCheckboxClick}
              selectedFilters={selectedFilters}
            />
          );
        })}
      </CollapsibleSection>
    </div>
  );
};

BadgeFilters.propTypes = {
  isActive: PropTypes.bool
};
