import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Routes } from 'utils';
import { SmartLink } from 'controls/smart_link';
import Icon from 'controls/icon';
import {
  getProfileAction,
  hasCredentialSolutionsAccess,
  hasMappingUploadAccess,
  hasWorkforceAccess,
  isActiveOrgMember
} from 'profile';
import { useEnabledFeatures } from 'utils/enabled_features';
import { faUserAstronaut } from '@fortawesome/pro-light-svg-icons/faUserAstronaut';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faBinoculars } from '@fortawesome/pro-light-svg-icons/faBinoculars';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import DashboardIcon from 'svg/dashboard.svg';
import BadgesIcon from 'svg/badges.svg';
import { FormattedMessage, injectIntl } from "react-intl";

import './header_menu_nav.sass';

// NOTE: Any change made here must also be made to _main_menu.html.haml

const getProfileLinkParams = (userVanitySlug) => ({
  action: Routes.userVanityUrl(userVanitySlug),
  className: 'profile',
  icon: faUserAstronaut,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.view_profile"
      defaultMessage="View Profile"
    />
  )
});
const editSkillsProfileParams = (userVanitySlug) => ({
  action: Routes.userEditSkillsProfileUrl(userVanitySlug),
  className: 'profile',
  icon: faUserAstronaut,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.view_profile"
      defaultMessage="View Profile"
    />
  )
});
const sendTranscriptLinkParams = {
  action: Routes.transcriptUrl.new(),
  className: 'transcript',
  icon: faEnvelope,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.send_transcript"
      defaultMessage="Send Transcript"
    />
  )
};
const settingsLinkParams = {
  action: Routes.editAccountUrl(),
  className: 'settings',
  icon: faCog,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.settings"
      defaultMessage="Settings"
    />
  )
};
const dashLinkParams = {
  action: Routes.earnerDashboardUrl(),
  className: 'dashboard',
  icon: DashboardIcon,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.dashboard"
      defaultMessage="Dashboard"
    />
  )
};
const badgesLinkParams = {
  action: Routes.earnerBadgesUrl(),
  className: 'badges',
  icon: BadgesIcon,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.badges"
      defaultMessage="Badges"
    />
  )
};
const orgManLinkParams = {
  action: Routes.profileUrl.organization(),
  className: 'org-management',
  icon: faBuilding,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.organization"
      defaultMessage="Organization"
    />
  )
};

const jobBoardLinkParams = {
  action: Routes.jobBoardUrl(),
  className: 'job-board',
  icon: faBinoculars,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.job_board"
      defaultMessage="Search Jobs"
    />
  )
};

const recruiterLinkParams = {
  action: Routes.recruitUrl(),
  className: 'recruit',
  icon: faBriefcase,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.recruit"
      defaultMessage="Recruit"
    />
  )
};

const talentMatcherLinkParams = {
  action: Routes.talentMatchUrl(),
  className: 'talent-match',
  icon: faBriefcase,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.talent_match"
      defaultMessage="Talent Match"
    />
  )
};

const workforceLinkParams = {
  action: Routes.workforceUrl.root(),
  className: 'workforce',
  icon: faUsers,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.workforce"
      defaultMessage="Workforce"
    />
  )
};

const mappingUploadLinkParams = {
  action: Routes.workforceUrl.mappingUpload(),
  className: 'mapping-upload',
  icon: faUsers,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.mapping_upload"
      defaultMessage="Mapping Upload"
    />
  )
};

const signOutLinkParams = {
  action: Routes.signOutUrl(),
  className: 'sign-out',
  icon: faSignOut,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.sign_out"
      defaultMessage="Sign Out"
    />
  )
};

const HeaderMenuNav = ({ handleClick, currentUser, intl }) => {
  const isFeatureEnabled = useEnabledFeatures();
  const renderNavLink = (paramsHash, ariaLabel = '', child = undefined) => {
    return (
      <SmartLink
        className={`main-menu-link main-menu-link--${paramsHash.className}`}
        onClick={handleClick}
        action={paramsHash.action}
        aria-label={ariaLabel}
      >
        <Icon image={paramsHash.icon} className="main-menu-icon" />
        <span>{paramsHash.linkText}</span>
        {child || undefined}
      </SmartLink>
    );
  };

  const handleBlur = (ev) => {
    if (!ev.currentTarget.contains(ev.relatedTarget)) {
      handleClick();
    }
  };

  const handleKeyDown = (ev) => {
    if (ev.key === 'Escape') {
      handleClick();
      ev.stopPropagation();
    }
  };

  const user = currentUser;
  const jobBoardEligible = user?.job_board_enabled; // eslint-disable-line camelcase
  const isActiveTalentMatcher = user?.active_talent_matcher; // eslint-disable-line camelcase
  const isRecruiter = user?.recruiter;
  const badgeCount = parseInt(user.pending_badge_count || 0);

  return (
    <div
      className="main-menu-container"
      id="header-nav-menu"
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    >
      <div className="main-menu main-menu--react-version">
        <div className="main-menu-body">
          <section className="main-menu-section">
            {renderNavLink(dashLinkParams, "Dashboard")}
            {!isFeatureEnabled('skills_profile') && renderNavLink(badgesLinkParams,
              intl.formatMessage({
                id: 'header_menu_nav.pending_badges',
                defaultMessage: 'Badges {badgeCount} pending badges'
              }, { badgeCount }),
              badgeCount &&
                <i className="notifications-counter notifications-counter--main-menu-icon">
                  {badgeCount}
                </i>
            )}
            {!isFeatureEnabled('skills_profile') &&
              renderNavLink(getProfileLinkParams(user.vanity_slug))}
            {isFeatureEnabled('skills_profile') &&
              renderNavLink(editSkillsProfileParams(user.vanity_slug))
            }
            {renderNavLink(sendTranscriptLinkParams)}
          </section>
          {jobBoardEligible &&
            <section className="main-menu-section">
              {renderNavLink(jobBoardLinkParams)}
            </section>
          }
          {(isActiveOrgMember(user) || isRecruiter || isActiveTalentMatcher) &&
            <section className="main-menu-section">
              {hasCredentialSolutionsAccess(user) && renderNavLink(orgManLinkParams)}
              {isRecruiter && renderNavLink(recruiterLinkParams)}
              {isActiveTalentMatcher &&
                renderNavLink(talentMatcherLinkParams)
              }
              {hasWorkforceAccess(user) && renderNavLink(workforceLinkParams)}
              {hasMappingUploadAccess(user) && renderNavLink(mappingUploadLinkParams)}
            </section>
          }
          <section className="main-menu-section">
            {renderNavLink(settingsLinkParams)}
            {renderNavLink(signOutLinkParams)}
          </section>
        </div>
      </div>
    </div>
  );
};

HeaderMenuNav.propTypes = {
  handleClick: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    pending_badge_count: PropTypes.number,
    recruiter: PropTypes.bool,
    vanity_slug: PropTypes.string,
    user_id: PropTypes.string,
    active_talent_matcher: PropTypes.bool
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  })
};

const mapStateToProps = (state) => {
  return {
    currentUser: getProfileAction.getResources(state)
  };
};

const ConnectedHeaderMenuNav = injectIntl(
  connect(mapStateToProps)(HeaderMenuNav)
);

export { ConnectedHeaderMenuNav as HeaderMenuNav };
export const testing = { HeaderMenuNav };
