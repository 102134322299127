import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { PropTypes } from 'prop-types';
import { injectIntl, useIntl } from 'react-intl';
import { makeClassName } from 'utils';
import isEqual from 'lodash.isequal';
import { tracking, withComponentTracking } from 'app_utils/tracking';
import { ImmediateProperty } from 'utils/react_utils';
import * as stringUtils from 'utils/string';
import { profileResource } from 'profile';
import { Search, SearchBar as BaseSearchBar, SearchResults } from 'controls/search';
import { withAriaHeadingProps } from 'controls/aria_attributes';
import { useWidthBreakpoints } from "utils/use_width_breakpoints";
import Results from './results';
import ResultsEmpty from './results_empty';
// import { openGlobalSearchUI, search, filteredBadgeSearch } from './action_creators';
import { openGlobalSearchUI, search as searchAction, filteredBadgeSearch } from './action_creators';
import { GLOBAL_SEARCH_TERM_KEY } from './constants';

import './global_search.sass';

const SearchBar = withAriaHeadingProps(BaseSearchBar, { nestedIn: 'labelProps' });

/**
 * Implements the global search functionality present in the header.  Includes an input for the
 * search term and a panel for results.
 *
 * @property {String} placeholder - Placeholder for the search bar input. Default: empty.
 * @property {Function} search - It is called to make a global search for the given input.
 * @property {Function} searchResults - Array with the result list.
 * @property {Object} searchResultsLoader - Status of the search request.
 * @property {String} ui - Passed to SearchBar. One of:['white', null]
 * @property {Boolean} showIcon - Show a search icon.
 */
const GlobalSearch = ({
  term,
  onSearchStarted,
  searchResultsLoader,
  openSearch,
  userId,
  componentTracking,
  onSearchCleared,
  collapsed,
  onComponentBlur,
  searchResults,
  search,
  globalAutocompleteSearch,
  badgeFilters,
  filteredBadgeSearch,
  filteredBadgeSearchResults,
  filteredBadgeSearchResultsLoader
}) => {
  const history = useHistory();
  const intl = useIntl();
  const termProp = term || '';
  const breakpoint = useWidthBreakpoints();

  useEffect(() => {
    /* If the component is loading with an initial search term, we need to set the styles for the opened state */
    if (termProp) {
      window.google_tag('event', 'global_search_exposed');
      onSearchStarted();
    }
  }, []);

  const shrinkCheck = () => {
    return (breakpoint === 'xs' || breakpoint === 'sm') && !focused && !(searchTerm || termProp);
  };

  const [searchTerm, setSearchTerm] = useState(termProp);
  const [clearSearch] = useState(new ImmediateProperty());
  const [focused, setFocused] = useState(false);
  const [shrink, setShrink] = useState(shrinkCheck());

  let normalizedCurrentSearch, normalizedPropsSearch, normalizedPrevPropsSearch;
  const prevSearch = useRef();
  const prevTerm = useRef();
  const prevBadgeFilters = useRef();
  const prevSearchResultsLoader = useRef();

  useEffect(() => {
    setShrink(shrinkCheck());
  }, [focused, breakpoint]);


  useEffect(() => {
    if (stringUtils.normalizeCaseInsensitive(term)) {
      _openSearch();
    }

    normalizedCurrentSearch = stringUtils.normalizeCaseInsensitive(searchTerm);
    const normalizedPreviousSearch = stringUtils.normalizeCaseInsensitive(prevSearch.current);

    if (normalizedCurrentSearch !== normalizedPreviousSearch) {
      componentDidUpdateSearchTermChanged(normalizedCurrentSearch, normalizedPreviousSearch);
    }

    normalizedPropsSearch = stringUtils.normalizeCaseInsensitive(term);
    normalizedPrevPropsSearch = stringUtils.normalizeCaseInsensitive(prevTerm.current);
    if (normalizedPropsSearch && normalizedPropsSearch !== normalizedPrevPropsSearch) {
      _openSearch();
    }

    const justSucceeded =
      searchResultsLoader.succeeded && !prevSearchResultsLoader?.current?.succeeded;
    if (justSucceeded) {
      componentDidUpdateSearchPerformed();
    }
    // check if filtered badge templates have changed
    if (!isEqual(prevBadgeFilters.current, badgeFilters) && badgeFilters) {
      filteredBadgeSearch({
        filterValues: badgeFilters,
        query: term
      });
    }
    prevBadgeFilters.current = badgeFilters;
    prevSearch.current = searchTerm;
    prevTerm.current = term;
    prevSearchResultsLoader.current = searchResultsLoader;
  }, [searchTerm, term, badgeFilters]);

  const _openSearch = useCallback(() => {
    openSearch();
  }, [openSearch]);

  /**
   * Tracking function that does not require user to be authenticated
   * If no userID is passed, we use componentTracking
   */
  const _componentTracking = (trackingParams) => {
    if (userId) {
      tracking.track({
        object_id: userId,
        ...trackingParams
      });
      return;
    }
    componentTracking(trackingParams.type, trackingParams?.['snapshot_json']);
  };
  _componentTracking.bind(this);

  /**
   * Called when the component updated and a search request just finished.
   */
  const componentDidUpdateSearchPerformed = () => {
    window.google_tag('event', 'global_search', {
      searchTerm: searchTerm
    });
    const trackingParams = {
      type: 'user.search',
      object_type: 'User',
      snapshot_json: {
        type: 'Search.Performed',
        keyword: searchTerm,
        has_results: hasResults()
      }
    };
    _componentTracking(trackingParams);
  };

  /**
   * Called when the component updated and the search term is different from the previous value.
   *
   * @param {string} normalizedCurrentSearch - the current search term, trimmed and lowercased
   * @param {string} normalizedPreviousSearch - the previous search term, trimmed and lowercased
   */
  const componentDidUpdateSearchTermChanged =
    (normalizedCurrentSearch, normalizedPreviousSearch) => {
      if (normalizedPreviousSearch && !normalizedCurrentSearch) {
        if (onSearchCleared) {
          onSearchCleared();
        }
      } else if (!normalizedPreviousSearch && normalizedCurrentSearch) {
        if (onSearchStarted) {
          onSearchStarted();
        }
      }
    };

  /**
   * Handler to close the search if the component loses focus either due to external click or
   * keyboard navigation.
   *
   * @param {Event} evt - React Event representing the interaction
   */
  const onBlur = (evt) => {
    if (collapsed) {
      return;
    }
    setFocused(false);
    setShrink(shrinkCheck());
    onComponentBlur && onComponentBlur(evt);
  };

  /**
   * The input has come into focus.
   *
   */
  const onFocus = () => setFocused(true);

  /**
   * Returns true if there are search results to show.
   *
   * @returns {boolean}
   */
  const hasResults = () => {
    return !!(
      hasSearchTerm() && searchResults[0]?.results?.length > 0
    );
  };

  /**
   * Returns suggestion if from searchResults.
   *
   * @returns {string}
   */
  const suggestion = () => {
    if (hasSearchTerm() && searchResults[0]?.suggestion) {
      return searchResults[0]?.suggestion;
    }
    return '';
  };

  /**
   * Returns true if user has provided a non-blank search term.
   * @returns {boolean}
   */
  const hasSearchTerm = () => {
    return searchTerm.trim() !== '';
  };

  /**
   * Returns true if search request is in progress.
   * @returns {boolean}
   */
  const isSearching = () => {
    return hasSearchTerm() && searchResultsLoader.pending;
  };

  /**
   * Returns a React component indicating that no search results were found, if a search term is
   * present, otherwise returns null.
   *
   * @returns {JSX.Element}
   */
  const renderEmpty = () => {
    if (hasSearchTerm()) {
      const suggestionResult = suggestion();
      return (
        <ResultsEmpty
          onSuggestClick={() => {
            if (suggestionResult?.length) {
              search(suggestionResult);
            }
          }}
          className="container" value={searchTerm} suggestion={suggestionResult}
        />
      );
    } else {
      return null;
    }
  };

  /**
   * Start the search.
   *
   * @param {String} term - The search term.
   */
  const performSearch = (term) => {
    setSearchTerm(term);
    if (term.trim()) {
      search(term);
    } else {
      const params = new URLSearchParams(location.hash.substring(1));
      params.delete(GLOBAL_SEARCH_TERM_KEY);
      history.replace({ ...history.location, hash: params.toString() });
    }
  };

  /**
   * Renders the component.
   *
   * @returns {React.element}
   */
  const hideResults = collapsed || !hasSearchTerm();
  const results = searchResults[0]?.results || [];
  const filteredBadgeResults = filteredBadgeSearchResults?.[0]?.results || [];


  const resultsClassName = makeClassName([
    'c-global-search__results',
    hideResults && 'c-global-search__results--is-hidden'
  ]);

  return (
    <Search
      className={makeClassName(!hideResults && 'c-global-search--open')}
      search={performSearch}
      hasTerm={hasSearchTerm()}
      showResults={!hideResults}
      searching={isSearching()}
      results={results}
      clearSearch={clearSearch}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      <div className="c-global-search__search-bar">
        <div className="container">
          <div className="row">
            <SearchBar
              globalAutocompleteSearch={globalAutocompleteSearch}
              isLoggedIn={!!userId}
              term={term}
              collapsed={collapsed}
              onShrink={setShrink}
              shrink={shrink}
              theme="new-theme"
              className={
                makeClassName("header-nav-item header-nav-item--search-bar",
                  shrink && "header-nav-item--search-bar--icon-only",
                  !userId && "header-nav-item--search-bar--icon-only-unauthenticated")
              }
              showIcon
              placeholder={
                intl.formatMessage({
                  id: "search.description",
                  defaultMessage: "Discover badges, skills or organizations"
                })
              }
            />
          </div>
        </div>
      </div>
      <SearchResults renderNoResults={renderEmpty} className={resultsClassName}>
        {
          !hideResults && results.length > 0 && (
            <Results
              className="container"
              searchResults={results || []}
              searchTerm={searchTerm}
              componentTracking={_componentTracking}
              customResponse={{
                BadgeTemplate: {
                  hasParams: !!badgeFilters,
                  results: filteredBadgeResults,
                  loader: filteredBadgeSearchResultsLoader
                }
              }}
            />
          )
        }
      </SearchResults>
    </Search>
  );
};

GlobalSearch.propTypes = {
  onComponentBlur: PropTypes.func,
  onSearchCleared: PropTypes.func,
  onSearchStarted: PropTypes.func,
  collapsed: PropTypes.bool,
  // From mapDispatchToProps
  openSearch: PropTypes.func,
  search: PropTypes.func,
  searchResults: PropTypes.array,
  searchResultsLoader: PropTypes.object,
  userId: PropTypes.string,

  // from WithLocation
  term: PropTypes.string,

  // from WithComponentTracking
  componentTracking: PropTypes.func,
  // global search header allows auto-complete
  globalAutocompleteSearch: PropTypes.bool,

  // badge filters in url
  badgeFilters: PropTypes.object,
  // badge filter search action
  filteredBadgeSearch: PropTypes.func,
  filteredBadgeSearchResultsLoader: PropTypes.object,
  // badge filter search results
  filteredBadgeSearchResults: PropTypes.array
};

const mapStateToProps = () => {
  return state => {
    const profile = profileResource(state);

    return {
      filteredBadgeSearchResults: filteredBadgeSearch.getResources(state),
      filteredBadgeSearchResultsLoader: filteredBadgeSearch.getStatus(state),
      searchResults: searchAction.getResources(state),
      searchResultsLoader: searchAction.getStatus(state),
      userId: profile && profile.id
    };
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  search: value => {
    props.search && props.search(value);
    return dispatch(searchAction.action({ q: value }));
  },
  filteredBadgeSearch: ({ filterValues, query }) => {
    const values = { q: query };
    Object.keys(filterValues).map(k => {
      if (filterValues[k]?.length) {
        // Directly assign the array to the values object
        values[k] = filterValues[k];
      }
    });

    return dispatch(filteredBadgeSearch.action(values));
  },
  openSearch: () => openGlobalSearchUI()(dispatch)
});

const Connect = connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(GlobalSearch)
);

/**
 * Helper to parse the stashed global search term out of the location, if it is present in the
 * URL hash.
 *
 * @param {RouterLocation} location - current location, including the hash, if any
 * @returns {string}
 */
const parseSearchTerm = (location) => {
  if (location.hash) {
    const asSearchParams = new URLSearchParams(location.hash.substring(1));
    const term = asSearchParams.get(GLOBAL_SEARCH_TERM_KEY);
    if (term) {
      return term;
    }
  }
  return '';
};

/**
 * Helper to parse the badge filters out of the location, if it is present in the
 * URL hash.
 *
 * @param {RouterHistory} history - current location, including the hash, if any
 * @returns {object}
 */
const getBadgeFiltersFromUrl = (history) => {
  const searchParams = new URLSearchParams(history.location.search);

  const badgeFilters = { type_category: [], cost: [], level: [] };

  for (const [key, value] of searchParams.entries()) {
    // Check if key ends with "[]" and remove it to match the key in badgeFilters object
    const normalizedKey = key.endsWith("[]") ? key.slice(0, -2) : key;

    // eslint-disable-next-line no-prototype-builtins
    if (badgeFilters.hasOwnProperty(normalizedKey)) {
      // If the key is already in the badgeFilters object, append the value to the existing array
      if (badgeFilters[normalizedKey].includes(value) === false) {
        badgeFilters[normalizedKey].push(value);
      }
    }
  }

  // don't return object if no filters are present
  if (Object.values(badgeFilters).every(array => array.length === 0)) {
    return null;
  }
  return badgeFilters;
};

/**
 * HoC to supply GlobalSearch with a term prop (taken from the address bar), and a search prop,
 * which will update the address bar with the new term.
 *
 * @param {React.Component} WrappedComponent - the component to wrap
 * @returns {React.Component}
 */
const withLocation = (WrappedComponent) => {
  const WithLocation = (props) => {
    const history = useHistory();
    const location = useLocation();

    const subProps = {
      ...props,
      term: parseSearchTerm(location),
      badgeFilters: getBadgeFiltersFromUrl(history)
    };

    subProps.search = useCallback((newTerm) => {
      const currentTerm = parseSearchTerm(history.location);
      const params = new URLSearchParams(location.hash.substring(1));
      params.set(GLOBAL_SEARCH_TERM_KEY, newTerm);
      if (currentTerm) {
        history.replace({ ...history.location, hash: params.toString() });
      } else {
        history.push({ ...history.location, hash: params.toString() });
      }
    }, []);

    return <WrappedComponent {...subProps} />;
  };
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WithLocation.displayName = `searchWithLocation(${displayName})`;
  return WithLocation;
};
const GlobalSearchWithLocation = withLocation(Connect);
const GlobalSearchWithComponentTracking = withComponentTracking(GlobalSearchWithLocation);

export { GlobalSearchWithComponentTracking as GlobalSearch };

export const testing = { GlobalSearch, withLocation, GLOBAL_SEARCH_TERM_KEY };
