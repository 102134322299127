import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Routes } from 'utils';
import { SmartLink } from 'controls/smart_link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

import './signin_signup_buttons.sass';

const CREATE_ACCOUNT_CLASSES = `header-nav-item header-nav-item--btn-container
  header-nav-item--btn-container--create-account-btn-container`;
const SIGN_IN_CLASSES = `header-nav-item header-nav-item--btn-container
  header-nav-item--btn-container--sign-in-btn-container`;

/**
 * Renders the Create Account and Sign In buttons conditionally, based on whether the current route
 * is already a sign-in/sign-up URL.
 *
 * @property {object} history - the history object (as provided by withRouter or React Router's
 *   history package)
 */
class SigninSignupButtons extends Component {
  /**
   * Instantiate the component and initialize state.
   *
   * @param {object} props - React component props
   */
  constructor(props) {
    super(props);
    this.state = { isSigninUrl: false, isMenuOpen: false };
  }

  /**
   * Toggles the menu open/closed.
   */
  toggleMenu = () => {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen
    }));
  };

  /**
   * The component just mounted.
   */
  componentDidMount() {
    this.historyUnlisten = this.props.history.listen(this.onLocationChange);
    this.onLocationChange(this.props.history.location);
  }

  /**
   * The component is about to unmount; stop listening to history location changes.
   */
  componentWillUnmount() {
    this.historyUnlisten();
  }

  /**
   * The history location changed; update the isSigninUrl state, if necessary.
   *
   * @param {{ pathname: string }} location - the current location
   */
  onLocationChange = (location) => {
    this.setState({ isSigninUrl: Routes.isSigninUrl(location.pathname) });
  };

  /**
   * Renders the component.
   *
   * @returns {React.element}
   */
  render() {
    const { isMenuOpen } = this.state;

    if (this.state.isSigninUrl) {
      return null;
    }
    return (
      <Fragment>
        <div className="menu-toggle" onClick={this.toggleMenu}>
          <div className="menu-icon menu-icon-bars">
            <FontAwesomeIcon className={isMenuOpen ? 'fa-times' : 'fa-bars'} icon={isMenuOpen ? faTimes : faBars}/>
          </div>
        </div>
        <div className={`menu-items ${isMenuOpen ? 'open' : ''}`}>
          <div className={CREATE_ACCOUNT_CLASSES}>
            <SmartLink className="create-account-btn button white" action="/users/sign_up">
              Create Account
            </SmartLink>
          </div>
          <div className={SIGN_IN_CLASSES}>
            <SmartLink className="sign-in-btn button" action="/users/sign_in">
              Sign In
            </SmartLink>
          </div>
        </div>
      </Fragment>
    );
  }
}

SigninSignupButtons.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func,
    location: PropTypes.shape({ pathname: PropTypes.string })
  })
};

export const testing = { SigninSignupButtons };

const SigninSignupButtonsWithRouter = withRouter(SigninSignupButtons);
export { SigninSignupButtonsWithRouter as SigninSignupButtons };
