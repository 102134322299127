import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { SiteHeader } from './site_header';

export class PortaledHeader extends Component {
  componentDidMount() {
    const hamlHeader =
      document.querySelector('.website-header-main-links-container.non-react-header');
    hamlHeader && (hamlHeader.hidden = true);
  }

  componentWillUnmount() {
    const hamlHeader =
      document.querySelector('.website-header-main-links-container.non-react-header');
    hamlHeader && (hamlHeader.hidden = false);
  }

  /**
   * Renders the component
   * @returns {React.element}
   */
  render() {
    const portalRoot = document.querySelector('#website-header');
    if (portalRoot) {
      return ReactDOM.createPortal(<SiteHeader/>, portalRoot);
    } else {
      return null;
    }
  }
}

