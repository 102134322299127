import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { makeClassName } from 'utils';

import './checkbox_widget.sass';

/**
 * Shows a checkbox UI. Separated out from those components for cases where such a
 * UI needs to be part of another composite component, e.g. a menuitemcheckbox in EllipsisMenu.
 *
 * @param {object} props
 *   @param {boolean} [props.checked] - whether the item is currently checked/selected; can also be
 *     achieved by using the checkbox-widget-checked mixin from
 *     checkbox_widget_mixins, in cases where changing the prop is problematic or non-
 *     performant
 *   @param {string} [props.className] - optional extra class to add to the top-level element
 *   @param {boolean} [props.hasError] - whether the component should be displayed in an error state
 * @returns {JSX.Element}
 * @constructor
 */
export const CheckboxWidget = (props) => {
  return (
    <div
      className={makeClassName(
        'c-checkbox-widget',
        props.checked && 'c-checkbox-widget--checked',
        props.hasError && 'c-checkbox-widget--error',
        props.disabled && 'c-checkbox-widget--disabled',
        props.className
      )}
    >
      <FontAwesomeIcon icon={faCheck} className="c-checkbox-widget__checkmark"/>
    </div>
  );
};

CheckboxWidget.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool
};
