import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { ImmediateProperty } from 'utils/react_utils';
import { SearchContext } from './search_context';

/**
 * Generic search component. Example usage:
 *
 * class MySearch extends Component {
 *   constructor(props) {
 *     super(props);
 *
 *     this.state = {
 *       term: '',
 *       results: [],
 *       searching: false
 *     };
 *   }
 *
 *   render() {
 *     return <Search
 *       search={term => {
 *         const hasText = term.trim() !== '';
 *         this.setState({term: term, searching: hasText});
 *
 *         if (hasText) {
 *           setTimeout(() => {
 *             if (term === 'a') {
 *               this.setState({results: ['alpha', 'aardvark', 'alkatraz']});
 *             } else if (term === 'b') {
 *               this.setState({results: ['bozo']});
 *             }
 *             this.setState({searching: false});
 *           }, 2000);
 *         }
 *       }}
 *       searching={this.state.searching}
 *       results={this.state.results}
 *       focusNow={new ImmediateProperty()}
 *       showResults={this.state.term.trim() !== ''}
 *     >
 *       <div className="container">
 *         <div className="row">
 *           <SearchBar ui="white" showIcon placeholder="Hi there"/>
 *         </div>
 *       </div>
 *       <SearchResults renderNoResults={()=>'No results found'}>
 *         {this.state.results.map(r => <div key={r}>{r}</div>)}
 *       </SearchResults>
 *     </Search>;
 *   }
 * }
 *
 *
 * @property {ImmediateProperty} focusNow - Focus the input field on mount, or at any time.
 * @property {ImmediateProperty} clearSearch - Clear the search input text.
 * @property {Boolean} searching - Is a search in progress? Internal factors will be used to
 *   determine this as well. This is used when a redux or ajax request is pending.
 * @property {Boolean} showResults - Show the search results.
 * @property {Boolean} showResultsWithNoTerm - show results (or the renderNoResults message),
 *   even if there's no search-term entered.
 * @property {function(String)} search - Called when it's time to do a search. This may be called
 *   with an empty string to clear the search.
 * @property {function(Event)} onFocus - Called when the field is focused.
 * @property {function(Event)} onBlur - Called when the field loses focus.
*/
export class Search extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();

    this.state = {
      typing: false,
      hasTerm: this.props.hasTerm || false,
      inputFocused: false
    };
  }

  /**
   * The user started typing.
   */
  onTypingStart = () => this.setState({ typing: true });

  /**
   * The user stopped typing.
   */
  onTypingEnd = () => this.setState({ typing: false });

  /**
   * Called when the search term is added or removed.
   *
   * @param {Boolean} hasTerm
   */
  onHasTermChange = hasTerm => {
    if (this.state.hasTerm !== hasTerm) {
      this.setState({ hasTerm: hasTerm });
    }
  };

  toggleInputFocused = () => this.setState({ inputFocused: !this.state.inputFocused })

  /**
   * Renders the component.
   *
   * @returns {React.element}
   */
  render() {
    const context = {
      // Immediate actions
      focusNow: this.props.focusNow,
      clearSearch: this.props.clearSearch,

      // State
      searching: this.props.searching || this.state.typing,
      showResults: this.props.showResults,
      results: this.props.results,
      hasTerm: this.state.hasTerm,
      showResultsWithNoTerm: this.props.showResultsWithNoTerm,
      focusContainer: this.props.focusContainer || this.container,
      inputFocused: this.state.inputFocused,

      // Callbacks
      search: this.props.search,
      onFocus: this.props.onFocus,
      onBlur: this.props.onBlur,
      onTypingStart: this.onTypingStart,
      onTypingEnd: this.onTypingEnd,
      onHasTermChange: this.onHasTermChange,
      toggleInputFocused: this.toggleInputFocused
    };

    return (
      <SearchContext.Provider value={context}>
        <div className={this.props.className} ref={this.container}>
          {this.props.children}
        </div>
      </SearchContext.Provider>
    );
  }
}

Search.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  hasTerm: PropTypes.bool,
  results: PropTypes.array.isRequired,
  search: PropTypes.func.isRequired,
  focusContainer: PropTypes.shape({ current: PropTypes.any }),
  focusNow: PropTypes.instanceOf(ImmediateProperty),
  clearSearch: PropTypes.instanceOf(ImmediateProperty),
  className: PropTypes.string,
  showResults: PropTypes.bool,
  showResultsWithNoTerm: PropTypes.bool,
  searching: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};
