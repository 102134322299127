import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeClassName, uniqueId } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import CollapsibleSection from './collapsible_section';
import { useWidthBreakpoints } from "utils/use_width_breakpoints";

import './collapsible_nav.sass';

/**
 * @typedef CollapsibleNavItem
 * @type object
 * @property {String} label - text label for the item
 * @property {*} icon - FontAwesome icon (value should be suitable for passing into
 *   <FontAwesomeIcon icon={icon}/>)
 * @property {boolean} disabled - whether the item is enabled or disabled
 * @property {String} path - if provided, the path the item should link to (should be compatible
 *   with React Router's Link element)
 * @property {function(object)} onClick - can be provided instead of path to do something other
 *   than change browser location upon clicking on the navigational element; receives the
 *   items' props as input
 */
let CollapsibleNavItemDocOnly; // Dummy variable for JSDoc

/**
 * This component shows a stacked navigation that collapses on mobile displays to show only the
 * currently-selected item by default.
 *
 * @property {Array<CollapsibleNavItem>} items - ordered list of navigation items to display
 * @property {function(object)} isCurrentItem - function that should return true if the provided
 *   item is the active one
 * @property {String} [className] - additional class name to add to the root element
 * @property {String} ariaLabel - sets the aria-label
 * @property {object} expandChildIfObjectTypeIsActive - Mapping of the elements that should be
 * rendered as child of active CollapsibleNavItem
 * Eg.: expandChildIfObjectTypeIsActive={{
 *             BadgeTemplate: () => (
 *               <BadgeFilters
 *                 isActive={this.isCurrentItem(Category.getCategoryByObjectType('BadgeTemplate'))}
 *               />
 *             )
 *           }}
 * will render <BadgeFilters /> as child of the active CollapsibleNavItem called 'BadgeTemplate'
 * @property {boolean} renderChildrenSectionOutside - whether the child section
 * (see expandChildIfObjectTypeIsActive) of a CollapsibleNavItem should be rendered
 * outside the navigator (directly below) instead of inside it.
 */
class CollapsibleNav extends Component {
  state = {
    focusedItem: null,
    mobileCollapsed: true
  };

  /**
   * Event handler for collapsing the menu on mobile.
   *
   * @param {Event} evt - React Event object that triggered the callback
   */
  closeMobileMenu = (evt) => {
    evt.preventDefault();
    this.setState({ mobileCollapsed: true });
  };

  /**
   * Find the active item from the items provided in props.
   *
   * @returns {CollapsibleNavItem}
   */
  currentItem() {
    return this.props.items.find(this.props.isCurrentItem);
  }

  onItemBlur(_item) {
    this.setState({ focusedItem: null });
  };

  onItemFocus(item) {
    this.setState({ focusedItem: item });
  };

  onKeyDown = (evt) => {
    if (evt.metaKey || evt.ctrlKey || evt.altKey) {
      return;
    }

    if (['Enter', 'Space'].indexOf(evt.key) > -1) {
      this.handleToggleMobileCollapsed(evt);
    }
  }

  /**
   * Event handler for toggling the collapsed state of the menu on mobile.
   *
   * @param {Event} evt - React Event that triggered the callback
   */
  handleToggleMobileCollapsed = (evt) => {
    evt.preventDefault();
    this.setState(state => ({
      ...state,
      mobileCollapsed: !state.mobileCollapsed
    }));
  };

  shouldRenderChild = (item) => {
    const currentItem = this.currentItem();
    const isCurrent = currentItem === item;

    if (this.props?.expandChildIfObjectTypeIsActive?.[item?.objectType]) {
      return (
        <CollapsibleSection
          tagName="div"
          role="region"
          allowOverflow
          collapsed={!isCurrent}
        >
          {this.props?.expandChildIfObjectTypeIsActive?.[item?.objectType]?.()}
        </CollapsibleSection>

      );
    }
    return null;
  }

  isSmallScreen() {
    return this.props.breakpoint === 'xs' || this.props.breakpoint === 'sm';
  }

  /**
   * Render the component.
   *
   * @returns {*}
   */
  render() {
    const className = makeClassName([
      'c-collapsible-nav',
      this.state.collapsed && 'c-collapsible-nav--mobile-collapsed',
      this.props.className
    ]);
    const currentItem = this.currentItem();
    const collapsibleSectionId = `list_${uniqueId()}`;

    // As part of our recent accessibility audit, one repeated theme is that when there are multiple nav elements
    // on the page, they need to have an aria-label (or aria-labeled-by) to help differentiate what each nav is for
    // the reason we add a default value in the first place is so that for previously done screens, or for poorly made
    // future screens, we can sort of save ourselves by having at least some aria-label though this should be passed
    // every time this component is used
    const ariaLabel = this.props.ariaLabel;

    return (
      <>
        <nav
          className={className}
          aria-label={ariaLabel}
        >
          <div
            className="c-collapsible-nav__item c-collapsible-nav__item--current"
            tabIndex="0"
            role="button"
            onClick={this.handleToggleMobileCollapsed}
            onKeyDown={this.onKeyDown}
            aria-expanded={!this.state.mobileCollapsed}
            aria-controls={collapsibleSectionId}
          >
            <div className="c-collapsible-nav__current-item-inner">
              {
                currentItem && (
                  <>
                    {renderIcon(currentItem.icon)}
                    <span className="c-collapsible-nav__current-item-label">{currentItem.label}</span>
                  </>
                )
              }
              {!currentItem && <span className="c-collapsible-nav__current-item-label">N/A</span>}
              <FontAwesomeIcon icon={faAngleDown} />
            </div>
          </div>
          <CollapsibleSection
            tagName="div"
            alwaysExpandedAboveBreakpoint="md"
            className="c-collapsible-nav__all-items-container"
            collapsed={this.state.mobileCollapsed}
            extraProps={{
              id: collapsibleSectionId,
              onClick: this.handleToggleMobileCollapsed
            }}
          >
            <ul>
              {this.props.items.map(item => {
                const isCurrent = currentItem === item;
                const itemClassName = makeClassName([
                  'c-collapsible-nav__item',
                  isCurrent && 'c-collapsible-nav__item--active',
                  item.disabled && 'c-collapsible-nav__item--disabled',
                  item === this.state.focusedItem && 'c-collapsible-nav__item--focused'
                ]);

                return (
                  <li key={item.path} className={itemClassName}>
                    <CollapsibleNavLink
                      {...item}
                      current={isCurrent}
                      // intentionally not using bound function syntax, because these handlers need
                      // the item reference
                      onBlur={() => this.onItemBlur(item)}
                      onFocus={() => this.onItemFocus(item)}
                    />
                    {!(this.isSmallScreen() && this.props.renderChildrenSectionOutside) &&
                      this.shouldRenderChild(item)}
                  </li>
                );
              })}
            </ul>

          </CollapsibleSection>
        </nav>
        {(this.isSmallScreen() && this.props.renderChildrenSectionOutside) &&
          this.shouldRenderChild(currentItem)}
      </>
    );
  }
}

CollapsibleNav.propTypes = {
  ariaLabel: PropTypes.string,
  breakpoint: PropTypes.string,
  className: PropTypes.string,
  isCurrentItem: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    icon: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    path: PropTypes.string,
    onClick: PropTypes.func
  })).isRequired,
  expandChildIfObjectTypeIsActive: PropTypes.object,
  renderChildrenSectionOutside: PropTypes.bool
};

const withBreakpoint = WrappedComponent => props => {
  const breakpoint = useWidthBreakpoints();
  return (
    <WrappedComponent
      {...props}
      breakpoint={breakpoint}
    />
  );
};

const CollapsibleNavWithBreakpoint = withBreakpoint(CollapsibleNav);
export default CollapsibleNavWithBreakpoint;

const renderIcon = icon => {
  const iconClass = 'c-collapsible-nav__icon';
  // Font Awesome icon
  if (icon.prefix && icon.iconName) {
    return (<FontAwesomeIcon icon={icon} className={iconClass} />);
  } else {
    return React.createElement(icon, { className: iconClass });
  }
};

/**
 * "Private" component used by CollapsibleNav to render an individual navigation item.
 */
const CollapsibleNavLink = (props) => {
  const handleClick = (evt) => {
    evt.preventDefault();
    if (!props.disabled) {
      props.onClick(props);
    }
  };

  const renderContent = React.useCallback(
    (props) => {
      return (
        <>
          <div className="c-collapsible-nav__icon-wrap">{renderIcon(props.icon)}</div>
          {props.label}
        </>
      );
    }, [props.label, props.icon]
  );

  const className = "c-collapsible-nav__link";
  const tabIndex = props.disabled ? -1 : 0;
  const eventProps = { onFocus: props.onFocus, onBlur: props.onBlur };
  let ariaAtts = { 'aria-disabled': !!props.disabled };

  if (props.current) {
    ariaAtts = {
      ...ariaAtts,
      'aria-current': props.current
    };
  }


  if (props.path) {
    return (
      <Link
        className={className}
        to={props.path}
        tabIndex={tabIndex}
        aria-disabled={!!props.disabled}
        {...eventProps}
        {...ariaAtts}
      >
        {renderContent(props)}
      </Link>
    );
  } else {
    return (
      <a
        href="#"
        className={className}
        onClick={handleClick}
        tabIndex={tabIndex}
        aria-disabled={!!props.disabled}
        {...eventProps}
        {...ariaAtts}
      >
        {renderContent(props)}
      </a>
    );
  }
};

CollapsibleNavLink.propTypes = {
  current: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onBlur: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onFocus: PropTypes.func.isRequired,
  path: PropTypes.string
};

export const TestCollapsibleNavLink = CollapsibleNavLink;
