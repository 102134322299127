export default class Category {
  constructor(label, objectType){
    this.label = label;
    this.objectType = objectType;
  }

  static getAllCategories(){
    if (!this.allCategories){
      this.allCategories = [
        new Category('Badges', 'BadgeTemplate'),
        new Category('Skills', 'Skill'),
        new Category('Organizations', 'Organization'),
        new Category('Occupations', 'Occupation')
      ];
    }
    return this.allCategories;
  }

  static getCategoryByObjectType(objectType){
    return this.getAllCategories().find(cat => cat.objectType === objectType);
  }
}
