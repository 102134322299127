import React, { forwardRef, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCombobox } from 'downshift';
import { makeClassName } from 'utils';
import { AutocompleteContext } from './autocomplete_context';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWidthBreakpoints } from 'utils/use_width_breakpoints';
import { useNoScroll } from 'controls/no_scroll';


import './search_bar_autocomplete.sass';


export const SearchBarAutocomplete = forwardRef(
  ({
    onBlur,
    id,
    className,
    onKeyDown,
    ...others
  }, ref) => {
    const {
      autocompleteResponse,
      isEnabled,
      term,
      debounceSearch,
      onChange,
      value
    } = useContext(AutocompleteContext);
    const [lockScroll, setLockScroll] = useState(false);
    const items = autocompleteResponse?.resources.map(i => i.id || i) || [];

    const {
      getInputProps,
      getItemProps,
      getMenuProps,
      isOpen,
      inputValue,
      highlightedIndex
    } = useCombobox({
      items,
      itemToString: (item) => (item || ''),
      onInputValueChange: (inputVal) => {
        const { selectedItem, isOpen } = inputVal;
        if (selectedItem?.length && !isOpen) {
          debounceSearch(inputVal.selectedItem);
        }
      },
      onSelectedItemChange: (selection) => {
        debounceSearch(selection.selectedItem);
      },
      inputValue: value
    });

    const breakpoint = useWidthBreakpoints();

    /*
    if we are in a mobile breakpoint and the dropdown is open,
    enable scroll lock to prevent background body from scrolling
    */
    useEffect(() => {
      const isDropdownOpen = isEnabled && term?.trim()?.length && isOpen;
      if ((breakpoint === 'xs' || breakpoint === 'sm') && isDropdownOpen) {
        return setLockScroll(true);
      }
      // only set state if needed
      setLockScroll((prevState) => {
        if (!prevState) {
          return prevState;
        }
        return false;
      });
    }, [breakpoint, isOpen, isEnabled, term]);

    useNoScroll(lockScroll);

    /*
    if autocomplete is enabled, intercept `enter` key
    */
    const onKeyDownEventHandler = (evt) => {
      if (isEnabled && evt.key === 'Enter') {
        return debounceSearch(term);
      }
      onKeyDown(evt);
    };

    const DropdownRenderer = () => (
      isEnabled && term?.trim()?.length && isOpen && items?.length
        ? (
          <ul
            {...getMenuProps({
              className: makeClassName(['searchbar-autocomplete__list-container',
                isOpen && items.length && 'searchbar-autocomplete__list-container--open'
              ])
            })}
          >
            {isOpen
              ? items
                .filter((item) => !inputValue || item.includes(inputValue?.trim()?.toLowerCase()))
                .map((item, index) => {
                  const searchTerm = inputValue.toLowerCase();
                  return (
                    <li
                      {...getItemProps({
                        key: item,
                        index,
                        item,
                        className: 'searchbar-autocomplete__list-item',
                        style: {
                          backgroundColor:
                          highlightedIndex === index ? '#efefef' : 'white'
                        }
                      })}
                      key={item}
                    >
                      <FontAwesomeIcon
                        className="searchbar-autocomplete__list-icon"
                        icon={faSearch}
                      />
                      {item.includes(searchTerm) ? (
                        <span>
                          {item.slice(0, item.indexOf(searchTerm))}
                          <strong>{searchTerm}</strong>
                          {item.slice(item.indexOf(searchTerm) + inputValue.length)}
                        </span>
                      ) : (
                        item
                      )}
                    </li>
                  );
                })
              : null}
          </ul>

        )
        : null
    );

    return (
      <>
        <div {...getMenuProps()} >
          <input
            {...getInputProps(
              {
                onChange,
                onBlur,
                id,
                ref,
                className: makeClassName([className,
                  isOpen && items.length && term?.length && !lockScroll && 'cr-search-bar__input--global-search--open'
                ]),
                onKeyDown: onKeyDownEventHandler,
                ...others
              })}
          />
        </div>
        <DropdownRenderer/>
      </>
    );
  });

SearchBarAutocomplete.propTypes = {
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  onKeyDown: PropTypes.func
};
