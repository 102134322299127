import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { useToggle } from 'utils/react_utils';
import { RoleButton } from 'aria/role_button';
import { AnchoredModal } from 'controls/anchored_modal';
import { FocusTrap } from 'controls/focus_trap';
import { HeaderMenuNav } from './header_menu_nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars';
import { useIntl } from "react-intl";

import { Avatar } from 'controls/avatar';

import './default_site_header.sass';

/**
 * Component that combines the toggle to show the main menu and the main menu itself.
 *
 * @param {object} props
 *   @param {object} props.currentUser - data about currently signed-in user
 * @returns {JSX.Element}
 * @constructor
 */
export const HeaderMenuNavToggle = (props) => {
  const containerRef = useRef();
  const toggleRef = useRef();
  const [menuNavOpen, toggleMenuNavOpen] = useToggle(false);

  FocusTrap.useException(containerRef.current, []);

  const intl = useIntl();

  const renderAvatar = () => {
    const user = props.currentUser || {};
    return (
      <Avatar
        className="header-nav-item__avatar"
        width={36}
        src={user.photo_url}
        alt="Open menu"
        emptyImage={
          <div className="header-nav-item__no-avatar">
            <FontAwesomeIcon icon={faUser} />
          </div>
        }
      />
    );
  };

  const renderMenu = () => {
    return (
      <div className="header-nav-item__menu">
        <FontAwesomeIcon icon={faBars} />
      </div>
    );
  };

  const ariaLabel = intl.formatMessage(
    {
      id: 'header_menu_nav.navigation_menu',
      defaultMessage: 'Navigation Menu'
    },
    { itemCount: props.currentUser.pending_badge_count }
  );

  const toggleMenuNoArgs = useCallback(() => {
    toggleMenuNavOpen();
  }, []);

  const anchorBounds = useCallback(() => {
    return toggleRef.current.getBoundingClientRect();
  }, []);

  return (
    <div
      className={makeClassName(
        'header-nav-item header-nav-item--profile-avatar-container',
        menuNavOpen && 'header-nav-item--menu-open'
      )}
      ref={containerRef}
    >
      <RoleButton
        tagName="div"
        className="header-nav-item header-nav-item--profile-avatar-container-button"
        onClick={toggleMenuNoArgs}
        title={ariaLabel}
        aria-controls="header-nav-menu"
        aria-expanded={menuNavOpen}
        ref={toggleRef}
      >
        <div className="header-nav-item__inner">
          {renderAvatar()}
          {renderMenu()}
          {
            props.currentUser.pending_badge_count > 0 && (
              <i
                aria-hidden
                className="notifications-counter notifications-counter--pending-badges-profile-avatar"
              >
                {props.currentUser.pending_badge_count}
              </i>
            )
          }
        </div>
      </RoleButton>
      <AnchoredModal
        alignment="bottom-right"
        show={menuNavOpen}
        onClose={() => toggleMenuNavOpen(false)}
        anchorBounds={anchorBounds}
      >
        {() => <HeaderMenuNav currentUser={props.currentUser} handleClick={toggleMenuNoArgs}/>}
      </AnchoredModal>
    </div>
  );
};

HeaderMenuNavToggle.propTypes = {
  currentUser: PropTypes.shape({
    pending_badge_count: PropTypes.numberisRequired
  }).isRequired
};
