import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { GLOBAL_SEARCH_TERM_KEY } from './constants';

/**
 * This component displays a message indicating that there are no results for the search term
 * entered by the user.
 */
const ResultsEmpty = memo(({ className, value, suggestion, onSuggestClick }) => {
  const renderWithSuggestion = () => {
    const currentPath = window.location.pathname;
    const href = `${currentPath}#${GLOBAL_SEARCH_TERM_KEY}=${suggestion}`;
    const suggestionLink = (
      <a
        onClick={() => onSuggestClick()}
        href={href}
      >
        {suggestion}
      </a>
    );

    return (
      <FormattedMessage
        id="search.empty_results_with_suggestion"
        defaultMessage='No search results found for "{search}". Did you mean {suggestion}?'
        values={{
          search: value,
          suggestion: suggestionLink
        }}
      />
    );
  };

  const renderWithoutSuggestion = () => (
    <FormattedMessage
      id="search.empty_results"
      defaultMessage='No search results found for "{search}".'
      values={{ search: value }}
    />
  );

  return (
    <div className={className}>
      {value &&
        <span>
          {suggestion
            ? renderWithSuggestion()
            : renderWithoutSuggestion()
          }
        </span>}
    </div>
  );
});

ResultsEmpty.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  suggestion: PropTypes.string,
  onSuggestClick: PropTypes.func
};

ResultsEmpty.displayName = 'ResultsEmpty';
export default ResultsEmpty;
