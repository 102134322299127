import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Routes } from 'utils';
import { profileResource } from './selectors';
import { userFormattedLocation, userFullName } from 'users';
import { Avatar } from 'controls/avatar';
import { ContextHeader } from 'header';
import EmptyImage from 'svg/empty_user.svg';
import { FormattedMessage } from "react-intl";

import './profile_context_header.sass';

/**
 * Presents a context-specific header that includes data about the current user.
 *
 * @property {Object} user - profile data for the user context
 */
const ProfileContextHeader = ({ user }) => {
  /**
   * Returns the formatted, full location string for the user provided in
   * @returns {string}
   */
  const makeUserLocation = () => userFormattedLocation(user);

  return (
    <ContextHeader
      backLink={Routes.userVanityUrl(user.vanity_slug)}
      backLinkAriaLabel="Back to profile"
    >
      <div className="cr-profile-context-header row justify-content-between align-items-center">
        <div className="cr-profile-context-header__profile col">
          <Avatar
            className="cr-profile-context-header__avatar"
            src={user.photo_url}
            width={77}
            emptyImage={<EmptyImage/>}
          />
          <div>
            <div className="cr-profile-context-header__name">
              {userFullName(user)}
            </div>
            {
              user.current_position_name &&
                <div className="cr-profile-context-header__position">
                  {user.current_position_name}
                </div>
            }
            {
              makeUserLocation() &&
                <div className="cr-profile-context-header__location">
                  {makeUserLocation()}
                </div>
            }
          </div>
        </div>
        <div className="cr-profile-context-header__dashboard-link">
          <Link to={Routes.earnerDashboardUrl()}>
            <FormattedMessage
              id="send_transcript.view_dashboard"
              defaultMessage="View Dashboard"
            />
          </Link>
        </div>
      </div>
    </ContextHeader>
  );
};

ProfileContextHeader.propTypes = {
  // Provided by connect
  user: PropTypes.shape({
    city: PropTypes.string,
    current_position_name: PropTypes.string,
    photo_url: PropTypes.string,
    state: PropTypes.string,
    vanity_slug: PropTypes.string
  }).isRequired
};

const mapStateToProps = (state) => ({ user: profileResource(state) });

const ConnectedProfileContextHeader = connect(mapStateToProps)(ProfileContextHeader);
export { ConnectedProfileContextHeader as ProfileContextHeader };

export const testing = { ProfileContextHeader };
