import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CollapsibleNav from 'controls/collapsible_nav';
import Category from './category';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faLightbulb } from '@fortawesome/pro-light-svg-icons/faLightbulb';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
import BadgesSVG from 'svg/badges.svg';
import { useIntl } from 'react-intl';
import { intlKeyFromValue } from 'translations';
import { BadgeFilters } from './badge_filters/badge_filters';

import './results_filters.sass';

const ICON_MAP = {
  All: faSearch,
  Badges: BadgesSVG,
  Skills: faLightbulb,
  Organizations: faBuilding,
  Occupations: faBriefcase
};

/**
 * This component displays filter options for search results categories as a CollapsibleNav.
 *
 * @property {string} [className] - optional, additional class name to add to root element
 * @property {Object.<string, object[]>} results - search results, grouped by type
 * @property {string} [selected=""] - the currently-selected filter
 */
const ResultsFilters = memo(({
  className,
  results,
  selected = '',
  handleFilterChange
}) => {
  const intl = useIntl();

  /**
   * Returns true if there are search results for the given objectType.
   *
   * @param {String} objectType - the object type for which to check for results
   * @returns {*}
   */
  const categoryHasResults = (objectType) => {
    if (!objectType) {
      return !!Object.values(results).find((results) => results.length > 0);
    } else {
      return results[objectType] && results[objectType].length > 0;
    }
  };

  /**
   * Returns true if the given item matches the currently-selected filter.
   *
   * @param {Object<{objectType: String}>} item - the item to check
   * @returns {boolean}
   */
  const isCurrentItem = (item) => item.objectType === selected;

  /**
   * Handler for clicks on a filter item.
   * @param {object} item - the item that was clicked
   * @param {string} item.objectType - the type of object represented by the clicked item
   */
  const handleClick = ({ objectType }) => {
    handleFilterChange(objectType);
  };

  /**
   * Returns list of category filters in format suitable for consumption by CollapsibleNav.
   * @returns {*[]}
   */
  const items = () => {
    const commonItemProps = (catLabel) => ({
      icon: ICON_MAP[catLabel],
      onClick: handleClick
    });

    const itemsArray = [
      {
        ...commonItemProps('All'),
        label: intl.formatMessage({
          id: 'search.all',
          defaultMessage: 'All'
        }),
        objectType: '',
        disabled: !categoryHasResults('')
      },
      ...Category.getAllCategories().map((category) => ({
        ...commonItemProps(category.label),
        label: intl.formatMessage({
          id: intlKeyFromValue(category.label, 'search'),
          defaultMessage: category.label
        }),
        objectType: category.objectType,
        disabled: !categoryHasResults(category.objectType)
      }))
    ];

    return itemsArray;
  };

  return (
    <div className={className}>
      <CollapsibleNav
        ariaLabel="Filter Search Results"
        className="c-global-search-results-filters"
        items={items()}
        isCurrentItem={isCurrentItem}
        renderChildrenSectionOutside
        expandChildIfObjectTypeIsActive={{
          BadgeTemplate: () => (
            <BadgeFilters
              isActive={isCurrentItem(Category.getCategoryByObjectType('BadgeTemplate'))}
            />
          )
        }}
      />
    </div>
  );
});

const resultObjectTypes = Category.getAllCategories().map((c) => c.objectType);

ResultsFilters.propTypes = {
  className: PropTypes.string,
  handleFilterChange: PropTypes.func.isRequired,
  results: PropTypes.shape(
    resultObjectTypes.reduce((result, objectType) => {
      result[objectType] = PropTypes.arrayOf(PropTypes.shape());
      return result;
    }, {})
  ).isRequired,
  selected: PropTypes.oneOf(['', ...resultObjectTypes])
};

ResultsFilters.displayName = 'ResultsFilters';
export default ResultsFilters;
