import React from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from 'controls/loading_spinner';
import { makeClassName } from 'utils';
import { SearchContextPropTypes, withSearchContextConsumer } from './search_context';
import './search_results.sass';

/**
 * Search results, for use with the Search component (as SearchResults).
 *
 * @param {Object} props
 * @property {String} props.className - Optional CSS class name.
 * @property {function:React.element} props.renderNoResults - Function to render the "no results"
 *   state.
 * @property {String} props.theme - CSS theme on of:
 *  default - the default container for search results
 *  org-mgmt - results appear as what looks like an expansion of the search bar
 *    similar to a dropdown. Styling matches the org-mgmt theme on SearchBar
 *
 * See search.jsx and search_context.jsx for additional properties.
 *
 * @returns {React.element}
 */
const Results = props => {
  const className = makeClassName([
    props.className,
    'cr-search-results',
    props.theme === 'org-mgmt' && "cr-search-results--org-mgmt",
    !props.inputFocused && "cr-search-results--unfocused",
    !props.showResults && 'cr-search-results--is-hidden'
  ]);

  if (props.searching && props.showResults) {
    return <div className={className}><LoadingSpinner position="below" size="medium"/></div>;
  }

  const hasResults = props.searching || props.results.length;

  return (
    <div className={className}>
      {props.showDivider && <div className="cr-search-results--divider"/>}
      {props.children}
      {!hasResults && props.showResults &&
        (props.hasTerm || props.showResultsWithNoTerm) &&
        props.renderNoResults()}
    </div>
  );
};
Results.displayName = 'SearchResults';

export const SearchResults = withSearchContextConsumer(Results);

SearchResults.propTypes = {
  className: PropTypes.string,
  renderNoResults: PropTypes.func
};

SearchResults.defaultProps = {
  theme: 'default'
};

Results.propTypes = {
  ...SearchResults.propTypes,
  ...SearchContextPropTypes
};

export const testing = { SearchResults: Results };
