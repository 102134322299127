import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';

import './radio_widget.sass';

/**
 * Shows a radio button UI
 *
 * @param {object} props
 *   @param {boolean} [props.selected] - whether the item is currently selected
 *   @param {boolean} [props.disabled] - whether the component should be disabled
 * @returns {JSX.Element}
 * @constructor
 */
export const RadioWidget = (props) => {
  const outerClassName = makeClassName(
    "cr-radio-widget__outer",
    props.disabled && "cr-radio-widget--disabled"
  );

  return (
    <div
      className={outerClassName}
    >
      {props.selected &&
        <div className="cr-radio-widget__inner"/>
      }
    </div>
  );
};

RadioWidget.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool
};
