import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleSection from 'controls/collapsible_section';
import { CheckboxWidget } from 'controls/checkbox_widget';
import { RadioWidget } from 'controls/radio_widget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { makeClassName } from 'utils';

export const Filter = ({
  label,
  filters,
  filterKey,
  icon,
  activeFilter,
  onFilterTypeClick,
  onFilterRadioCheckboxClick,
  selectedFilters
}) => (
  <div className="c-global-search-results-filters__type-container--expanded">
    <div
      onClick={() => onFilterTypeClick(filterKey)} className={
        makeClassName([
          "c-global-search-results-filter__type-heading-container",
          activeFilter[filterKey] && 'active'
        ])
      }
    >
      <div className="c-global-search-results-filters__type-container">
        <FontAwesomeIcon icon={icon} className="c-global-search-results-filters__icon" />
        <div>{label}</div>
      </div>
      <FontAwesomeIcon icon={activeFilter[filterKey] ? faChevronUp : faChevronDown} className="c-global-search-results-filters__icon" />
    </div>
    <CollapsibleSection
      tagName="div"
      role="region"
      collapsed={!activeFilter[filterKey]}
      aria-expanded={activeFilter[filterKey]}
      aria-labelledby="Badges"
    >
      {filters.map(f => (
        <div
          onClick={() => onFilterRadioCheckboxClick({ filterType: filterKey, filterItem: f.label })}
          className="c-global-search-results-filter__badge-filter-checkbox-item"
          key={`${f.key}`}
        >
          {filterKey !== 'cost'
            ? (
              <CheckboxWidget
                checked={selectedFilters[filterKey]?.includes(f.label)}
                className="cr-checkbox__checkbox-widget c-global-search-results-filter__badge-filter_widget"
              />
            )
            : (
              <RadioWidget
                selected={selectedFilters[filterKey].includes(f.label)}
              />
            )
          }
          {f.label}
        </div>
      ))}
      <div />
    </CollapsibleSection>
  </div>
);

Filter.propTypes = {
  label: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  filterKey: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  activeFilter: PropTypes.object.isRequired,
  onFilterTypeClick: PropTypes.func.isRequired,
  onFilterRadioCheckboxClick: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired
};
