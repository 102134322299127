import React from 'react';
import PropTypes from 'prop-types';
import { ImmediateProperty } from 'utils/react_utils';

export const SearchContext = React.createContext({});

export const SearchContextPropTypes = {
  focusNow: PropTypes.instanceOf(ImmediateProperty),
  clearSearch: PropTypes.instanceOf(ImmediateProperty),

  searching: PropTypes.bool,
  showResults: PropTypes.bool,
  hasTerm: PropTypes.bool,
  showResultsWithNoTerm: PropTypes.bool,
  results: PropTypes.array.isRequired,
  focusContainer: PropTypes.shape({ current: PropTypes.any }),
  inputFocused: PropTypes.bool,

  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onTypingStart: PropTypes.func.isRequired,
  onTypingEnd: PropTypes.func.isRequired,
  onHasTermChange: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  toggleInputFocused: PropTypes.func.isRequired
};

export const withSearchContextConsumer = OriginalComponent => {
  const Consumer = props =>
    <SearchContext.Consumer>{context =>
      <OriginalComponent {...props} {...context}/>
    }
    </SearchContext.Consumer>;

  const displayName = OriginalComponent.displayName || OriginalComponent.name || 'Component';
  Consumer.displayName = `withSearchContextConsumer(${displayName})`;
  return Consumer;
};
