/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { SmartLink } from 'controls/smart_link';
import { getProfileAction } from 'profile';
import { Routes } from 'utils';
import { GlobalSearch } from 'global_search';
import { SigninSignupButtons } from './signin_signup_buttons';
import { HeaderMenuNavToggle } from './header_menu_nav_toggle';
import { connect } from 'react-redux';

import './default_site_header.sass';

const DefaultSiteHeader = ({ currentUser }) => {
  /**
   * Supplies url for the Credly logo on the left side of the header.
   * @returns {String} The URL for the link
   */
  const getLogoUrl = () => {
    let url;
    const user = currentUser;
    if (userLoggedIn() && user) { // && user isn't _really_ necessary, since userLoggedIn uses the same prop
      if (user.memberships.length > 0 && user.redirect_to_organizations) {
        url = Routes.profileUrl.organization();
      } else {
        url = Routes.earnerDashboardUrl();
      }
    } else {
      url = Routes.marketingPageRootUrl();
    }
    return url;
  };

  /**
   * Tests whether the user is logged in
   * @returns {Boolean}
   */
  const userLoggedIn = () => {
    return !currentUser.anonymous;
  };

  /**
   * Callback passed to GlobalSearch, to react to a search starting, by hiding
   * overflow of the underlying page.
   * @type Function
   */
  const handleSearchStarted = () => {
    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector('main').style.visibility = 'hidden';
  };

  /**
   * Callback passed to GlobalSearch, to react to search being cleared, by
   * restoring the underlying page.
   * @type Function
   */
  const handleSearchTermCleared = () => {
    document.querySelector('body').style.overflow = '';
    document.querySelector('main').style.visibility = '';
  };


  /**
   * Renders the "Global Search" and nav-link menu buttons
   * @returns {React.element}
   */
  const renderSearchAndLinks = () => {
    return (
      <>
        <GlobalSearch
          globalAutocompleteSearch
          onSearchStarted={handleSearchStarted}
          onSearchCleared={handleSearchTermCleared}
        />
        <HeaderMenuNavToggle currentUser={currentUser}/>
      </>
    );
  };


  /**
   * Renders the component.
   * @returns {React.element}
   */
  return (
    <div className="website-header-main-links-container">
      <div className="grid website-header-main-links">
        <SmartLink
          id="acclaim-logo"
          action={getLogoUrl()}
        >
          Credly
        </SmartLink>
        <nav aria-label="Header">
          {
            userLoggedIn()
              ? renderSearchAndLinks()
              : (
                <>
                  <GlobalSearch
                    globalAutocompleteSearch
                    onSearchStarted={handleSearchStarted}
                    onSearchCleared={handleSearchTermCleared}
                  />
                  <SigninSignupButtons />
                </>
              )
          }
        </nav>
      </div>
    </div>
  );
};

DefaultSiteHeader.propTypes = {
  currentUser: PropTypes.shape({
    anonymous: PropTypes.bool,
    pending_badge_count: PropTypes.number
  })
};

const mapStateToProps = (state) => {
  return {
    currentUser: getProfileAction.getResources(state) || { anonymous: true }
  };
};

const ConnectedDefaultSiteHeader = connect(mapStateToProps)(DefaultSiteHeader);

export { ConnectedDefaultSiteHeader as DefaultSiteHeader };
export const testing = DefaultSiteHeader;
